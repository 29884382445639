<template>
    <section class="page-content">
        <OnlineMentalHealthScreeningComponent/>
        <!-- <AboutTopComponent/>
        <HeartCenter/>
        <VisionMissionComponent/> -->
    </section>
</template>

<script setup>
    import OnlineMentalHealthScreeningComponent from '../components/questionnaire/OnlineMentalHealthScreeningComponent.vue';
    // import AboutTopComponent from '../components/about/AboutTopComponent.vue';
    // import HeartCenter from '@/components/home/HeartCenter.vue';
    // import VisionMissionComponent from '../components/about/VisionMissionComponent.vue';
    window.scrollTo(0, 0);
</script>

<style scoped>
  /*  */
</style>