import { useDoctorStore } from "@/stores/doctorList";
import { scheduleHour, showNotification } from "./general";
export function searchbarInput(id){
    useDoctorStore().searchbarInput(document.getElementById(id).value);
}
export function selectSchedule(sp, option, schid){
    var text = '';
    for(var i = 0; i < sp.schedule.length; i++){
        if(sp.schedule[i].doctor_schedule_id == schid){
            if(sp.schedule[i].appoinment != 1){
                text = sp.name + ', ' + scheduleHour(sp.schedule[i].time_start) + ' - ' + scheduleHour(sp.schedule[i].time_end);
            }else{
                text = sp.name + ', <span style="font-style: italic;">By Appointment</span>';
            }
        }
    }
    document.getElementById(option.input).value = sp.id;
    document.getElementById(option.name).innerHTML = text;
    useDoctorStore().selectPoliSchedule({id: schid, text: text});
}
export function poliRegistration(){
    const elinput = document.getElementsByClassName('poli-elput');
    // const input = document.getElementsByClassName('poli-input');
    // var vdfalse = 0;
    // var params = [];
    if(elinput.length == 3){
        showNotification('', 'Pilih Poli dahulu !', 2000, 'var(--primary)');
    }else if(elinput.length == 4){
        showNotification('', 'Pilih Dokter dahulu !', 2000, 'var(--primary)');
    }else if(elinput.length == 5){
        // for(var i = 0; i < input.length; i++){
        //     const name = input[i].getAttribute('name');
        //     const value = input[i].value.trim();
        //     const validate = inputValidation(input[i]);
        //     if(validate.status == 1){
        //         params.push({[name]: value});
        //     }else{
        //         vdfalse = vdfalse + 1;
        //     }
        // }
        useDoctorStore().submitPoliRegistration();
        // if(vdfalse == 0){
        // }
    }
}
export function inputValidation(input){
    var result = {status: 1, message: 'validasi berhasil'};
    // if(input.hasAttribute('email')){
    //     if(!emailValidation(input)){
    //         result = {status: 0, message: input.getAttribute('label') + ' harus valid'};
    //     }
    // }
    // if(input.hasAttribute('phone')){
    //     if(!phoneValidation(input)){
    //         result = {status: 0, message: input.getAttribute('label') + ' harus valid'};
    //     }
    // }
    if(input.hasAttribute('required')){
        if(!input.value){
            result = {status: 0, message: input.getAttribute('label') + ' diperlukan'};
        }
    }
    return result;
}
let lastInputSelectSpecialistValue = '';

const debouncedInputSelectSpecialist = debounce((e) => {
    useDoctorStore().searchbarSelect('Semua Dokter', 'all');
    lastInputSelectSpecialistValue = e.target.value;
    useDoctorStore().searchbarSelectSpecialist.id = null;
    useDoctorStore().inputSelectSpecialist(lastInputSelectSpecialistValue);
}, 500);
export function inputSelectSpecialist(e){
    debouncedInputSelectSpecialist(e);
    // useDoctorStore().searchbarSelect('Semua Dokter', 'all');
    if(e.srcElement.value){
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'flex';
    }else{
        useDoctorStore().searchbarSelectSpecialist.input.value = '';
        e.srcElement.parentElement.nextElementSibling.children[0].style.display = 'none';
    }
}
export function debounce(func, delay, immediate = false) {
    let timer;
    return function (...args) {
        const executeImmediately = immediate && !timer;
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            if (!immediate) {
                func(...args);
            }
        }, delay);
        if (executeImmediately) {
            func(...args);
        }
    };
}
export function clearInputSelectSpecialist(){
    const input = document.getElementById('input-select-specialist');
    input.value = '';
    useDoctorStore().searchbarSelectSpecialist.input.value = '';
    useDoctorStore().searchbarSelectSpecialist.id = null;
    useDoctorStore().inputSelectSpecialist('');
    // useDoctorStore().searchDoctorAndSpecialistFromServer();
    input.parentElement.nextElementSibling.children[0].style.display = 'none';
}
export function searchbarSelect(name, slug){
    clearInputSelectSpecialist();
    useDoctorStore().searchbarSelect(name, slug);
}
export function organizeSchedule(schedule){
    // console.log(schedule);
    const dayids= [];
    const days = [];
    schedule.forEach((element) => {
        if(element.day_string.toLowerCase() == 'senin'){
            element.day_id = 1;
        }else if(element.day_string.toLowerCase() == 'selasa'){
            element.day_id = 2;
        }else if(element.day_string.toLowerCase() == 'rabu'){
            element.day_id = 3;
        }else if(element.day_string.toLowerCase() == 'kamis'){
            element.day_id = 4;
        }else if(element.day_string.toLowerCase() == 'jumat'){
            element.day_id = 5;
        }else if(element.day_string.toLowerCase() == 'sabtu'){
            element.day_id = 6;
        }else if(element.day_string.toLowerCase() == 'minggu'){
            element.day_id = 7;
        }
        if(!dayids.includes(element.day_id)){
            dayids.push(element.day_id);
        }
    });
    dayids.forEach((id) => {
        days.push({day_id: id, schedules: []});
    });
    schedule.forEach((element) => {
        // console.log(element);
        days.forEach((day) => {
            if(element.day_id == day.day_id){
                day.name = element.day_string;
                day.schedules.push({id: element.id, starts: element.start_hour, ends: element.end_hour});
            }
        });
    });
    days.forEach((day) => {
        day.schedules.reverse();
    });
    // console.log(days);
    return days;
}















// export function organizeSchedule(schedule){
//     // console.log(schedule);
//     const dayids= [];
//     const days = [];
//     schedule.forEach((element) => {
//         if(element.day_string.toLowerCase() == 'senin'){
//             element.day_id = 1;
//         }else if(element.day_string.toLowerCase() == 'selasa'){
//             element.day_id = 2;
//         }else if(element.day_string.toLowerCase() == 'rabu'){
//             element.day_id = 3;
//         }else if(element.day_string.toLowerCase() == 'kamis'){
//             element.day_id = 4;
//         }else if(element.day_string.toLowerCase() == 'jumat'){
//             element.day_id = 5;
//         }else if(element.day_string.toLowerCase() == 'sabtu'){
//             element.day_id = 6;
//         }else if(element.day_string.toLowerCase() == 'minggu'){
//             element.day_id = 7;
//         }
//         if(!dayids.includes(element.day_id)){
//             dayids.push(element.day_id);
//         }
//     });
//     dayids.forEach((id) => {
//         days.push({day_id: id, schedules: []});
//     });
//     schedule.forEach((element) => {
//         // console.log(element);
//         days.forEach((day) => {
//             if(element.day_id == day.day_id){
//                 day.name = element.day_string;
//                 day.schedules.push({id: element.id, starts: element.start_hour, ends: element.end_hour});
//             }
//         });
//     });
//     days.forEach((day) => {
//         day.schedules.reverse();
//     });
//     // console.log(days);
//     return days;
// }