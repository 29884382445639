<template>
    <nav class="navbar" id="navbar">
        <div class="nav-pad">
            <div class="navbar-inner">
                <div class="navbar-logo">
                    <router-link to="/" @click="pageScrollTop">
                        <div class="navbar-logo-img">
                            <img src="./assets/melindacvc-logo.webp" alt="melinda cardiovascular center logo"/>
                        </div>
                        <div class="navbar-logo-text">
                            <span>Melinda 3</span>
                        </div>
                    </router-link>
                </div>
                <div class="navbar-menu" id="navbar-menu">
                    <!-- {{ loggedIn }} -->
                    <div class="nm-inner">
                        <div class="nm-part">
                            <router-link to="/" class="nm-part-link" @click="menuToggle">Home</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/services" class="nm-part-link" @click="menuToggle">Our Services</router-link>
                        </div>
                        <div class="nm-part">
                            <a href="https://melindahospital.com/articles" target="_blank" class="nm-part-link" @click="menuToggle" >Articles</a>
                        </div>
                        <div class="nm-part">
                            <router-link to="/rooms" class="nm-part-link" @click="menuToggle" >Room</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/doctors" class="nm-part-link" @click="menuToggle" >Doctors</router-link>
                        </div>
                        <div class="nm-part">
                            <!-- <router-link to="/about" class="nm-part-link" @click="menuToggle" >About Us</router-link> -->
                            <div class="nm-part-arrow" @mouseenter="navbarMenuSelect('npa-button-about', 'enter');" @mouseleave="navbarMenuSelect('npa-button-about', 'leave');">
                                <div class="npa-button" id="npa-button-about" @click="navbarMenuSelect('npa-button-about', 'click');">
                                    <div class="npa-button-text">
                                        <span>About Us</span>
                                    </div>
                                    <div class="npa-button-icon">
                                        <img src="../src/assets/images/icons/arrow-1.png" alt="melinda cardiovascular center"/>
                                    </div>
                                </div>
                                <div class="npa-option">
                                    <div class="npa-option-inner">
                                        <div class="npa-option-button">
                                            <router-link to="/about" @click="menuToggle">
                                                <span>About Us</span>
                                            </router-link>
                                        </div>
                                        <div class="npa-option-button">
                                            <router-link to="/careers" @click="menuToggle">
                                                <span>Careers</span>
                                            </router-link>
                                        </div>
                                        <div class="npa-option-button">
                                            <router-link to="/auction" @click="menuToggle">
                                                <span>Auction</span>
                                            </router-link>
                                        </div>
                                        <div class="npa-option-button">
                                            <router-link to="/link" @click="menuToggle">
                                                <span>Link</span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="nm-part">
                            <router-link to="/gallery" class="nm-part-link" @click="menuToggle" >Gallery</router-link>
                        </div>
                        <!-- <div class="nm-part">
                            <router-link to="/contact-us" class="nm-part-link" @click="menuToggle" >Contact</router-link>
                        </div> -->
                        <!-- <div class="nm-part" @click="menuToggle">
                            <router-link class="nm-part-link" to="/order/cart">
                                Cart [ <span class="cart-num">{{ cartNum }}</span> / <span class="cart-product">{{ cartProduct }}</span> ]
                            </router-link>
                        </div> -->
                        <!-- <div class="nm-part nm-mobile-loggedin" v-if="loggedIn">
                            <span>{{ loggedIn.name }}</span>
                        </div> -->
                        <div class="nm-part nm-mobile">
                            <router-link to="/contact-us">
                                <span @click="menuToggle();">Contact</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="nm-mobile-close" @click="menuHide">
                        <img src="./assets/images/icons/mbclose.png" alt="p3k melinda" />
                    </div>
                </div>
                <div class="navbar-menu-bg" id="navbar-menu-bg" @click="menuHide"></div>
                <!-- <div class="navbar-login-form" id="navbar-login-form" v-if="!loggedIn">
                    <div class="nlf-inner">
                        <div class="ni-head">
                            <span class="nlf-close" @click="hideLogin">x</span>
                        </div>
                        <div class="nlf-content" id="nlf-content">
                            <div class="nlf-part nlf-login">
                                <div class="nlf-head">
                                    <h2>Login</h2>
                                </div>
                                <div class="nlf-body">
                                    <div class="nlf-input" id="nlf-input-usermail">
                                        <input type="text" id="nlf-mailphone-input" @focus="inputFb('nlf-input-usermail', 1);" @keydown="toPasswordInput" @blur="inputFb('nlf-input-usermail', 0);" />
                                        <div class="nlf-label" @click="inputFb('nlf-input-usermail', 2);">Username atau Email</div>
                                    </div>
                                    <div class="nlf-input" id="nlf-input-password">
                                        <input type="password" id="nlf-password-input" alias="Password" @focus="inputFb('nlf-input-password', 1);" @keydown="clickLogin" @blur="inputFb('nlf-input-password', 0);" />
                                        <div class="nlf-label" @click="inputFb('nlf-input-password', 2);">Password</div>
                                    </div>
                                    <div class="nlf-button">
                                        <div class="nlf-button-text" id="nbt-login" @click="signIn">Login</div>
                                    </div>
                                </div>
                                <div class="nlf-foot">
                                    <p>Belum punya akun ? <router-link class="nlf-link" to="" @click="hideLogin">Daftar</router-link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="navbar-button">
                    <!-- <div class="nb-login-loggedin" v-if="loggedIn" @mouseenter="optionShow('nll-select')" @click="optionToggle('nll-select')" @mouseleave="optionHide('nll-select')">
                        <div class="nll-button">{{ loggedIn.name }}</div>
                        <div class="nll-select" id="nll-select">
                            <div class="nll-option" @click="goTo('/account/profile')">Profile</div>
                            <div class="nll-option" @click="signOut">Logout</div>
                        </div>
                    </div> -->
                    <div class="nb-login">
                        <router-link to="/contact-us">
                            <span>Contact</span>
                        </router-link>
                    </div>
                    <div class="nb-menumob">
                        <img src="./assets/images/icons/mobmenu.png" alt="p3k melinda" @click="menuShow" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div class="content">
        <router-view/>
    </div>
    <footer>
        <div class="footer-top">
            <div class="staff">
                <div class="staff-icon">
                    <img src="./assets/images/icons/cb-phone.png" alt="melinda cardiovascular center phone" class="staff-icon-phone" />
                    <img src="./assets/images/icons/male-staff.png" alt="melinda cardiovascular center staff" class="staff-icon-male" />
                </div>
                <div class="staff-text">
                    <p>Please feel free to contact our friendly reception staff with any medical enquiry, or call (022) 6319 9888</p>
                </div>
            </div>
            <div class="sosign">
                <div class="socials">
                    <a href="https://www.facebook.com">
                    <div class="socials-icon">
                        <img src="./assets/images/icons/cc-facebook.png" alt="melinda cardiovascular center socials"/>
                    </div>
                    </a>
                    <a href="https://www.twitter.com">
                    <div class="socials-icon">
                        <img src="./assets/images/icons/cc-twitter.png" alt="melinda cardiovascular center socials"/>
                    </div>
                    </a>
                    <a href="https://www.linkedin.com">
                    <div class="socials-icon">
                        <img src="./assets/images/icons/cc-linkedin.png" alt="melinda cardiovascular center socials"/>
                    </div>
                    </a>
                    <a href="https://www.instagram.com">
                    <div class="socials-icon">
                        <img src="./assets/images/icons/cc-instagram.png" alt="melinda cardiovascular center socials"/>
                    </div>
                    </a>
                </div>
                <div class="signup">
                    <div class="signup-button">
                        <button @click="showModal('signup-modal');">Pendaftaran</button>
                    </div>
                    <div class="modal signup-modal" id="signup-modal">
                        <div class="signup-modal-head">
                            <div class="smh-close" @click="hideModal('signup-modal');">
                                <span>x</span>
                            </div>
                        </div>
                        <div class="signup-modal-body">
                            <div class="smb-inner">
                                <div class="smb-form">
                                    <div class="smb-form-input poli-elput" v-if="poliSelect">
                                        <input type="hidden" name="specialist" label="Poli" class="poli-input" id="sfi-input-specialist"/>
                                        <div class="sfi-label">Pilih Poli</div>
                                        <div class="sfi-select">
                                            <div class="sfi-select-button" id="ssb-signup-specialist" @click="optionToggle('ssb-signup-specialist');">
                                                <div class="btn-cover float-button"></div>
                                                <div class="ssb-text" id="ssb-text-signup" v-if="poliSelect.selected">
                                                    <span>{{ poliSelect.selected.name }}</span>
                                                </div>
                                                <div class="ssb-text" id="ssb-text-signup" v-else>
                                                    <span>Pilih Spesialis</span>
                                                </div>
                                                <div class="float-icon">
                                                    <img src="./assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                </div>
                                            </div>
                                            <div class="sfi-select-option float">
                                                <div class="sso-notif" v-if="poliSelect.specialists.length > 0">
                                                    <span>{{ 'ada ' + poliSelect.specialists.length + ' poli' }}</span>
                                                </div>
                                                <div class="sso-button-list">
                                                    <div class="sso-button" v-for="sp in poliSelect.specialists" :key="sp" @click="selectOption(sp, {button: 'ssb-signup-specialist', input: 'sfi-input-specialist', name: 'ssb-text-signup'}); useDoctorStore().selectPoli(sp.slug);">
                                                        <span>{{ sp.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="smb-form-input poli-elput" v-if="poliSelect?.doctors">
                                        <input type="hidden" name="doctor" label="Dokter" class="poli-input" id="sfi-input-doctor"/>
                                        <div class="sfi-label">Pilih Dokter</div>
                                        <div class="sfi-select">
                                            <div class="sfi-select-button" id="ssb-signup-doctor" @click="optionToggle('ssb-signup-doctor');">
                                                <div class="btn-cover float-button"></div>
                                                <div class="ssb-text" id="st-signup-doctor" v-if="poliSelect.doctors.selected">
                                                    <span>{{ poliSelect.doctors.selected.name }}</span>
                                                </div>
                                                <div class="ssb-text" id="st-signup-doctor" v-else>
                                                    <span>Pilih Dokter</span>
                                                </div>
                                                <div class="float-icon">
                                                    <img src="./assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                </div>
                                            </div>
                                            <div class="sfi-select-option float">
                                                <div class="sso-notif" v-if="poliSelect.doctors.list.length > 0">
                                                    <span>{{ 'ada ' + poliSelect.doctors.list.length + ' dokter' }}</span>
                                                </div>
                                                <div class="sso-button-list">
                                                    <div class="sso-button" v-for="doctor in poliSelect.doctors.list" :key="doctor" @click="useDoctorStore().selectPoliDoctor(doctor);">
                                                        <span>{{ doctor.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="smb-form-input poli-elput" v-if="poliSelect?.schedule">
                                        <input type="hidden" name="schedule" label="Jam Praktek" class="poli-input" id="sfi-input-schedule"/>
                                        <div class="sfi-label">Pilih Jam Praktek</div>
                                        <div class="sfi-select">
                                            <div class="sfi-select-button" id="ssb-signup-schedule" @click="optionToggle('ssb-signup-schedule');">
                                                <div class="btn-cover float-button"></div>
                                                <div class="ssb-text" id="st-signup-schedule">
                                                    <span>Pilih Jam Praktek</span>
                                                </div>
                                                <div class="float-icon">
                                                    <img src="./assets/images/icons/arrow-1.png" alt="rsia melinda"/>
                                                </div>
                                            </div>
                                            <div class="sfi-select-option float">
                                                <div class="sso-notif" v-if="poliSelect.schedule.length > 0">
                                                    <span>{{ 'ada ' + poliSelect.schedule.length + ' jadwal, klik pada tombol waktu untuk memilih' }}</span>
                                                </div>
                                                <div class="sso-button-list">
                                                    <div class="sso-button sso-button-daytime" v-for="sc in poliSelect.schedule" :key="sc">
                                                        <div class="sso-button-schedule" @click="selectSchedule(sc, {button: 'ssb-signup-schedule', input: 'sfi-input-schedule', name: 'st-signup-schedule'}, 'time.doctor_schedule_id');">
                                                            <div class="sbs-day">
                                                                <span>{{ sc.day }}</span>
                                                            </div>
                                                            <div class="sbs-clock">
                                                                <div class="sbs-clock-list">
                                                                    <div class="scl-inner" @click="selectSchedule(sc, {button: 'ssb-signup-schedule', input: 'sfi-input-schedule', name: 'st-signup-schedule'}, time.doctor_schedule_id);" v-if="sc.appoinment != true">
                                                                        <span>{{ scheduleHour(sc.time_start) }} - {{ scheduleHour(sc.time_end) }}</span>
                                                                    </div>
                                                                    <div class="scl-inner scl-inner-appointment" @click="selectSchedule(sc, {button: 'ssb-signup-schedule', input: 'sfi-input-schedule', name: 'st-signup-schedule'}, 'time.doctor_schedule_id');" v-else>
                                                                        <span>By Appointment</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="smb-form-input poli-elput">
                                        <div class="sfi-label">Nama Pasien</div>
                                        <input type="text" name="name" class="poli-input" id="poli-input-name" label="Nama Pasien" placeholder="masukkan nama pasien" autocomplete="off"/>
                                        <div class="sfi-alert"></div>
                                    </div>
                                    <div class="smb-form-input poli-elput">
                                        <div class="sfi-label">Nomor Ponsel</div>
                                        <input type="text" name="phone" class="poli-input" id="poli-input-phone" label="Nomor Ponsel" placeholder="nomor ponsel" autocomplete="off"/>
                                        <div class="sfi-alert"></div>
                                    </div>
                                    <div class="smb-form-input">
                                        <button @click="poliRegistration">Daftar Sekarang</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-middle">
            <div class="fm-logo">
                <img src="./assets/melinda-cvc-logo-text.webp" alt="melinda cardiovascular center logo"/>
            </div>
            <div class="fm-text">
                <div class="fm-office">
                    <h2>Office</h2>
                    <p>Jl. Dr. Cipto no 11, Bandung. Jawa Barat - Indonesia</p>
                </div>
                <div class="fm-contact">
                    <h2>Contact</h2>
                    <p>IGD : (022) 4226 5911</p>
                    <p>Hunting : (022) 6319 9888</p>
                    <p>Pendaftaran : 0813 1284 5500 (Call & WhatsApp)</p>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="fb-left">
                <p>© {{ new Date().getFullYear() }} melinda cardio vascular, All Rights Reserved.</p>
            </div>
            <div class="fb-right">
                <div class="fbr-medals">
                    <img src="./assets/images/icons/medals.png" alt="melinda cardiovascular center medals"/>
                </div>
                <div class="fbr-text">
                    <router-link to="/">Privacy Policy</router-link>
                    <router-link to="/" class="cookies-link">Cookies</router-link>
                </div>
            </div>
        </div>
        <div class="notification" id="notification">
            <div class="notification-head">
                <h5 class="notification-message" id="notification-title"></h5>
            </div>
            <div class="notification-body">
                <p class="notification-message" id="notification-message"></p>
            </div>
        </div>
        <div class="link-tree-icon" id="link-tree-icon">
            <a href="https://linktr.ee/rskjpmelindacvc" target="_blank">
                <img src="./assets/images/logos/mcvclinks.png" alt="melinda cvc"/>
            </a>
        </div>
        <!-- <div class="chat-box" id="chat-box">
            <div class="chat-box-head">
                <div class="chat-box-button" @click="chatToggle('chat-box', 'chat-box-body');">
                    <div class="cbb-chaticon">
                        <img src="./assets/images/icons/chaticon.png" alt="melinda cardiovascular center" />
                    </div>
                    <div class="cbb-text">Chat</div>
                    <div class="cbb-icon" id="cbb-icon">
                        <img src="./assets/images/icons/arrow-1.png" alt="melinda cardiovascular center" />
                    </div>
                </div>
            </div>
            <div class="chat-box-body" id="chat-box-body">
                <div class="chat-box-stage" id="chat-box-stage">
                    <div class="cbs-part chat-box-select" id="chat-box-select" v-if="loggedIn">
                        <div class="chat-box-option" id="cbo-doctor" @click="selectChatWith('doctor'); chatToggle('chat-box', 'chat-box-body'); pageScrollTop();" v-if="chatDoctor.length < 1">Chat dengan Dokter</div>
                        <div class="chat-box-option" id="cbo-doctor" @click="goChatDoctor" v-else>Chat dengan Dokter</div>
                        <div class="chat-box-option" @click="selectChatWith('cs');">Chat dengan Customer Service</div>
                    </div>
                    <div class="cbs-part chat-box-select" id="chat-box-select" v-else>
                        <div class="chat-box-option" id="cbo-doctor" @click="selectChatWith('doctor'); chatToggle('chat-box', 'chat-box-body'); pageScrollTop();">Chat dengan Dokter</div>
                        <div class="chat-box-option" @click="selectChatWith('cs');">Chat dengan Customer Service</div>
                    </div>
                    <div class="cbs-part cbs-doctor" id="cbs-doctor" v-if="loggedIn">
                        <div class="cbs-doctor-inner">
                            <div class="cbs-doctor-head">
                                <span class="cbs-back" @click="cbsBack">
                                    <img src="./assets/images/icons/arrow-1.png" alt="melinda cardiovascular center" />
                                    <span> kembali</span>
                                </span>
                            </div>
                            <div class="cbs-doctor-body">
                                <div class="cdb-inner" v-if="chatDoctor.length > 0">
                                    <div class="cdb-tab">
                                        <div class="cdb-tab-head">
                                            <template v-for="(data, index) in chatDoctor" :key="data.username">
                                                <div class="cth-inner cth-inner-active" :id="'cth-inner-' + data.room.name" @click="showCtb(data.room.name)" v-if="index == 0">
                                                    <div class="cth-inner-text" v-if="data.doctor">{{ data.doctor.name }}</div>
                                                    <div class="cth-inner-text" v-else>{{ data.user[0].name }}</div>
                                                </div>
                                                <div class="cth-inner" :id="'cth-inner-' + data.room.name" @click="showCtb(data.room.name)" v-else>
                                                    <div class="cth-inner-text" v-if="data.doctor">{{ data.doctor.name }}</div>
                                                    <div class="cth-inner-text" v-else>{{ data.room.name }}</div>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="cdb-tab-body">
                                            <template v-for="(data, index) in chatDoctor" :key="data.username">
                                                <div class="ctb-inner ctb-inner-active" :id="'ctb-inner-' + data.room.name" v-if="index == 0">
                                                    <div class="ctb-screen" :id="'chat-screen-' + data.room.name">
                                                        <template v-if="data.room.messages">
                                                            <template v-for="message in data.room.messages" :key="message.id">
                                                                <div class="ctb-screen-board csboard-you" v-if="message.sender.username == loggedIn.username">
                                                                    <div class="csboard-inner">
                                                                        <div class="csb-sender csb-sender-you">
                                                                            <span>Anda</span>
                                                                        </div>
                                                                        <div class="csb-message csb-message-you">{{ message.message }}</div>
                                                                        <div class="csb-report csb-report-you">
                                                                            <span class="csb-sender-time" v-if="message.sent.time == 0">Pending...</span>
                                                                            <span class="csb-sender-time" v-else>{{ message.sent.time }}</span>
                                                                            <span class="csb-sender-check csc-clock" v-if="message.sent.time == 0">
                                                                            <img src="../src/assets/images/loaders/swatch.gif" alt="melinda cardiovascular center"/></span>
                                                                            <span class="csb-sender-check csc-one" v-else>
                                                                                <img src="../src/assets/images/icons/check1.png" alt="melinda cardiovascular center"/>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="ctb-screen-board csboard-they" v-else>
                                                                    <div class="csboard-inner">
                                                                        <div class="csb-sender csb-sender-they">
                                                                            <span>{{ message.sender.name }} </span>
                                                                        </div>
                                                                        <div class="csb-message csb-message-they">{{ message.message }}</div>
                                                                        <div class="csb-report csb-report-they">
                                                                            <span class="csb-sender-time">{{ message.sent.time }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </template>
                                                    </div>
                                                    <div class="ctb-field" v-if="data.status == 'ACTIVE'">
                                                        <div class="ctb-field-input">
                                                            <div class="cfi-inner">
                                                                <input type="text" :id="'chat-input-' + data.room.name" @keydown="enterSend" />
                                                            </div>
                                                        </div>
                                                        <div class="ctb-field-button" :id="'ctb-field-button-' + data.room.name" @click="sendMessage(data.room.name, loggedIn.username)">
                                                            <span>Kirim</span>
                                                        </div>
                                                    </div>
                                                    <div class="ctb-field ctb-field-unpaid" v-else-if="data.status == 'UNPAID'">
                                                        <p>Sesi Chat ini masih menunggu pembayaran</p>
                                                    </div>
                                                    <div class="ctb-field ctb-field-paid" v-else-if="data.status == 'PAID'">
                                                        <p v-if="data.doctor">Sesi Chat ini menunggu dokter</p>
                                                        <p v-else>Sesi Chat ini menunggu dokter (anda)</p>
                                                    </div>
                                                    <div class="ctb-field ctb-field-done" :id="'cfd-' + data.room.name" v-else-if="data.status == 'DONE'">
                                                        <div class="cfd-inner" v-if="data.doctor">
                                                            <p>Terimakasih telah menggunakan layanan chat kami</p>
                                                            <div class="rate-button">
                                                                <button @click="rateForm(data.room.name);">Beri Rating</button>
                                                            </div>
                                                            <div class="rate-modal" :id="'rate-modal-' + data.room.name">
                                                                <div class="rate-modal-inner">
                                                                    <div class="rate-modal-close" @click="rateForm(data.room.name);">
                                                                        <span>x</span>
                                                                    </div>
                                                                    <div class="rate-modal-star">
                                                                        <input type="hidden" :id="'rms-input-' + data.room.name" value="5"/>
                                                                        <div class="rms-inner">
                                                                            <div class="rms-icon" value="1" @click="setStar(data.room.name, 1);">
                                                                                <img src="../src/assets/images/icons/silverstar.png" class="rms-icon-silver" alt="melinda cardiovascular center"/>
                                                                                <img src="../src/assets/images/icons/goldstar.png" class="rms-icon-gold rig-first" alt="melinda cardiovascular center"/>
                                                                            </div>
                                                                            <div class="rms-icon" value="2" @click="setStar(data.room.name, 2);">
                                                                                <img src="../src/assets/images/icons/silverstar.png" class="rms-icon-silver" alt="melinda cardiovascular center"/>
                                                                                <img src="../src/assets/images/icons/goldstar.png" class="rms-icon-gold" alt="melinda cardiovascular center"/>
                                                                            </div>
                                                                            <div class="rms-icon" value="3" @click="setStar(data.room.name, 3);">
                                                                                <img src="../src/assets/images/icons/silverstar.png" class="rms-icon-silver" alt="melinda cardiovascular center"/>
                                                                                <img src="../src/assets/images/icons/goldstar.png" class="rms-icon-gold" alt="melinda cardiovascular center"/>
                                                                            </div>
                                                                            <div class="rms-icon" value="4" @click="setStar(data.room.name, 4);">
                                                                                <img src="../src/assets/images/icons/silverstar.png" class="rms-icon-silver" alt="melinda cardiovascular center"/>
                                                                                <img src="../src/assets/images/icons/goldstar.png" class="rms-icon-gold" alt="melinda cardiovascular center"/>
                                                                            </div>
                                                                            <div class="rms-icon" value="5" @click="setStar(data.room.name, 5);">
                                                                                <img src="../src/assets/images/icons/silverstar.png" class="rms-icon-silver" alt="melinda cardiovascular center"/>
                                                                                <img src="../src/assets/images/icons/goldstar.png" class="rms-icon-gold" alt="melinda cardiovascular center"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="rate-modal-input">
                                                                        <textarea :id="'rmi-comment-' + data.room.name"></textarea>
                                                                    </div>
                                                                    <div class="rate-modal-submit">
                                                                        <button @click="rateSubmit(data.room.name, data.transaction);">Kirim</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cfd-inner" v-else>
                                                            <p>Chat ini telah selesai</p>
                                                        </div>
                                                    </div>
                                                    <div class="ctb-field ctb-field-rated" v-else-if="data.status == 'RATED'">
                                                        <div class="cfd-inner" v-if="data.doctor">
                                                            <p>Sesi chat ini telah selesai, untuk mengaktifkan kembali silakan lakukan transaksi baru dengan dokter ini</p>
                                                        </div>
                                                        <div class="cfd-inner" v-else>
                                                            <p>Chat ini telah selesai</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ctb-inner" :id="'ctb-inner-' + data.room.name" v-else>
                                                    <div class="ctb-screen" :id="'chat-screen-' + data.room.name">
                                                        <template v-if="data.room.messages">
                                                            <template v-for="message in data.room.messages" :key="message.id">
                                                                <div class="ctb-screen-board csboard-you" v-if="message.sender.username == loggedIn.username">
                                                                    <div class="csboard-inner">
                                                                        <div class="csb-sender csb-sender-you">
                                                                            <span>Anda</span>
                                                                        </div>
                                                                        <div class="csb-message csb-message-you">{{ message.message }}</div>
                                                                        <div class="csb-report csb-report-you">
                                                                            <span class="csb-sender-time" v-if="message.sent.time == 0">Pending...</span>
                                                                            <span class="csb-sender-time" v-else>{{ message.sent.time }}</span>
                                                                            <span class="csb-sender-check csc-clock" v-if="message.sent.time == 0">
                                                                            <img src="../src/assets/images/loaders/swatch.gif" alt="melinda cardiovascular center"/></span>
                                                                            <span class="csb-sender-check csc-one" v-else>
                                                                                <img src="../src/assets/images/icons/check1.png" alt="melinda cardiovascular center"/>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="ctb-screen-board csboard-they" v-else>
                                                                    <div class="csboard-inner">
                                                                        <div class="csb-sender csb-sender-they">
                                                                            <span>{{ message.sender.name }} </span>
                                                                        </div>
                                                                        <div class="csb-message csb-message-they">{{ message.message }}</div>
                                                                        <div class="csb-report csb-report-they">
                                                                            <span class="csb-sender-time">{{ message.sent.time }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </template>
                                                    </div>
                                                    <div class="ctb-field" v-if="data.status == 'ACTIVE'">
                                                        <div class="ctb-field-input">
                                                            <div class="cfi-inner">
                                                                <input type="text" :id="'chat-input-' + data.room.name" @keydown="enterSend" />
                                                            </div>
                                                        </div>
                                                        <div class="ctb-field-button" :id="'ctb-field-button-' + data.room.name" @click="sendMessage(data.room.name, loggedIn.username)">
                                                            <span>Kirim</span>
                                                        </div>
                                                    </div>
                                                    <div class="ctb-field ctb-field-unpaid" v-else-if="data.status == 'UNPAID'">
                                                        <p>Sesi Chat ini masih menunggu pembayaran</p>
                                                    </div>
                                                    <div class="ctb-field ctb-field-paid" v-else-if="data.status == 'PAID'">
                                                        <p>Sesi Chat ini menunggu dokter</p>
                                                    </div>
                                                    <div class="ctb-field ctb-field-done" :id="'cfd-' + data.room.name" v-else-if="data.status == 'DONE'">
                                                        <div class="cfd-inner" v-if="data.doctor">
                                                            <p>Terimakasih telah menggunakan layanan chat kami</p>
                                                            <div class="rate-button">
                                                                <button @click="rateForm(data.room.name);">Beri Rating</button>
                                                            </div>
                                                            <div class="rate-modal" :id="'rate-modal-' + data.room.name">
                                                                <div class="rate-modal-inner">
                                                                    <div class="rate-modal-close" @click="rateForm(data.room.name);">
                                                                        <span>x</span>
                                                                    </div>
                                                                    <div class="rate-modal-star">
                                                                        <input type="hidden" :id="'rms-input-' + data.room.name" value="5"/>
                                                                        <div class="rms-inner">
                                                                            <div class="rms-icon" value="1" @click="setStar(data.room.name, 1);">
                                                                                <img src="../src/assets/images/icons/silverstar.png" class="rms-icon-silver" alt="melinda cardiovascular center"/>
                                                                                <img src="../src/assets/images/icons/goldstar.png" class="rms-icon-gold rig-first" alt="melinda cardiovascular center"/>
                                                                            </div>
                                                                            <div class="rms-icon" value="2" @click="setStar(data.room.name, 2);">
                                                                                <img src="../src/assets/images/icons/silverstar.png" class="rms-icon-silver" alt="melinda cardiovascular center"/>
                                                                                <img src="../src/assets/images/icons/goldstar.png" class="rms-icon-gold" alt="melinda cardiovascular center"/>
                                                                            </div>
                                                                            <div class="rms-icon" value="3" @click="setStar(data.room.name, 3);">
                                                                                <img src="../src/assets/images/icons/silverstar.png" class="rms-icon-silver" alt="melinda cardiovascular center"/>
                                                                                <img src="../src/assets/images/icons/goldstar.png" class="rms-icon-gold" alt="melinda cardiovascular center"/>
                                                                            </div>
                                                                            <div class="rms-icon" value="4" @click="setStar(data.room.name, 4);">
                                                                                <img src="../src/assets/images/icons/silverstar.png" class="rms-icon-silver" alt="melinda cardiovascular center"/>
                                                                                <img src="../src/assets/images/icons/goldstar.png" class="rms-icon-gold" alt="melinda cardiovascular center"/>
                                                                            </div>
                                                                            <div class="rms-icon" value="5" @click="setStar(data.room.name, 5);">
                                                                                <img src="../src/assets/images/icons/silverstar.png" class="rms-icon-silver" alt="melinda cardiovascular center"/>
                                                                                <img src="../src/assets/images/icons/goldstar.png" class="rms-icon-gold" alt="melinda cardiovascular center"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="rate-modal-input">
                                                                        <textarea :id="'rmi-comment-' + data.room.name"></textarea>
                                                                    </div>
                                                                    <div class="rate-modal-submit">
                                                                        <button @click="rateSubmit(data.room.name, data.transaction);">Kirim</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cfd-inner" v-else>
                                                            <p>Chat ini telah selesai</p>
                                                        </div>
                                                    </div>
                                                    <div class="ctb-field ctb-field-rated" v-else-if="data.status == 'RATED'">
                                                        <div class="cfd-inner" v-if="data.doctor">
                                                            <p>Sesi chat ini telah selesai, untuk mengaktifkan kembali silakan lakukan transaksi baru dengan dokter ini</p>
                                                        </div>
                                                        <div class="cfd-inner" v-else>
                                                            <p>Chat ini telah selesai</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cbs-cs" id="cbs-cs">
                        <div class="cbs-cs-inner">
                            <div class="cbs-cs-head">
                                <span class="cbs-back" @click="cbsBack">
                                    <img src="./assets/images/icons/arrow-1.png" alt="melinda cardiovascular center" />
                                    <span> kembali</span>
                                </span>
                            </div>
                            <div class="cbs-cs-body">
                                <p>Chat dengan Customer Service saat ini belum tersedia</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="go-top" @click="pageScrollTop">
            <img src="./assets/images/icons/arrow-1.png" alt="melinda cardiovascular center" />
        </div>
    </footer>
</template>
<script setup>
    import { navbarMenuSelect, showModal, hideModal, optionToggle, selectOption, scheduleHour } from '@/functions/general';
    import { computed } from '@vue/reactivity';
	import { useDoctorStore } from './stores/doctor';
    import { selectSchedule, poliRegistration } from '@/functions/doctor';
    const poliSelect = computed(() => useDoctorStore().poliSelect);
//   import { onMounted } from 'vue';
//   import { computed } from "@vue/runtime-core";
//   import { useGeneralStore } from "./stores/general";
//   import { useTransactionStore } from "./stores/transaction";
// import { getCookie, showLogin, hideLogin, goTo, navbarMenuSelect } from './functions/general';
//   import { signIn, toPasswordInput, clickLogin, signOut } from './functions/account';
//   import { sendMessage, enterSend, chatToggle, selectChatWith, goChatDoctor, cbsBack, showCtb } from './functions/chat';
//   import { useAccountStore } from './stores/account';
//   import { useChatStore } from './stores/chat';
//   import { useDoctorStore } from './stores/doctor';
//   // import router from './router';
//   const waitingProcess = computed(() => useGeneralStore().waitingProcess);
//   const loggedIn = computed(() => useAccountStore().loggedIn);
//   // const cookieCart = computed(() => useTransactionStore().cookieCart);
//   const cartProduct = computed(() => useTransactionStore().cartProduct);
//   const cartNum = computed(() => useTransactionStore().cartNum);
//   const chatDoctor = computed(() => useChatStore().chatDoctor);
//   // const chatCs = computed(() => useChatStore().chatCs);
// if(getCookie('session')){
//     // useAccountStore().userSync();
// }
//   onMounted(() => {
//       if(getCookie('cart')){
//           // console.log(getCookie('cart'));
//           useTransactionStore().setCart(JSON.parse(getCookie('cart')));
//       }
//   });
function menuToggle(){
    const nm = document.getElementById('navbar-menu');
    const nb = document.getElementById('navbar-menu-bg');
    if(screen.width <= 750){
        if(nm.style.left == '26%'){
            nm.style.left = '110%';
            nb.style.width = '0';
        }else{
            nm.style.left = '26%';
            nb.style.width = '100%';
        }
    }
    const np = document.getElementsByClassName('npa-option');
    for(var i = 0; i < np.length; i++){
        np[i].classList.remove('npa-option-active');
        np[i].style.height = 0;
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
function menuShow(){
    document.getElementById('navbar-menu').style.left = '26%';
    document.getElementById('navbar-menu-bg').style.width = '100%';
}
function menuHide(){
    document.getElementById('navbar-menu').style.left = '110%';
    document.getElementById('navbar-menu-bg').style.width = '0';
}
function pageScrollTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
// function inputFb(a, b){
//     const si = document.getElementById(a);
//     if(si){
//         if(b == 1){ // 1 == input is now focus
//             si.style.borderBottom = '1px solid var(--primary)';
//             si.children[1].style.top = '-50%';
//             // si.children[1].style.left = '0.5rem';
//             si.children[1].style.fontSize = '0.5rem';
//             si.children[1].style.width = 'auto';
//             si.children[1].style.height = 'auto';
//             si.children[1].style.paddingLeft = '0';
//         }else if(b == 2){ // 2 for initialize input focus
//             si.children[0].focus();
//         }else{ // else == input is now blur
//             si.style.borderBottom = '1px solid lightgrey';
//             if(!si.children[0].value){
//                 si.children[1].style.top = '0';
//                 // si.children[1].style.left = '0';
//                 si.children[1].style.fontSize = '0.9rem';
//                 si.children[1].style.width = '100%';
//                 si.children[1].style.height = '100%';
//                 si.children[1].style.paddingLeft = '0.5rem';
//             }
//         }
//     }
// }
// function optionToggle(id){
//     const tg = document.getElementById(id);
//     if(tg.style.display == 'block'){
//         optionHide(id);
//     }else{
//         optionShow(id);
//     }
// }
// function optionShow(id){
//     const tg = document.getElementById(id);
//     tg.style.display = 'block';
// }
// function optionHide(id){
//     const tg = document.getElementById(id);
//     tg.style.display = 'none';
// }
// function rateForm(chat){
//     const cf = document.getElementById('cfd-' + chat);
//     const rm = document.getElementById('rate-modal-' + chat);
//     if(rm.style.bottom == '0px'){
//         cf.style.height = 'auto';
//         rm.style.bottom = '-200%';
//     }else{
//         cf.style.height = '200px';
//         rm.style.bottom = '0px';
//     }
// }
// function setStar(chat, n){
//     const ic = document.getElementsByClassName('rms-icon');
//     for(var i = 0; i < ic.length; i++){
//         ic[i].children[1].style.display = 'none';
//     }
//     for(i = 0; i < ic.length; i++){
//         if(ic[i].getAttribute('value') <= n){
//             ic[i].children[1].style.display = 'block';
//         }
//     }
//     document.getElementById('rms-input-' + chat).value = n;
// }
// function rateSubmit(chat, tx){
//     const rt = document.getElementById('rms-input-' + chat);
//     const rc = document.getElementById('rmi-comment-' + chat);
//     const params = {transaction: tx, rating: rt.value, comment: rc.value};
//     useDoctorStore().rateDoctor(params);
// }
</script>
<style>
    @font-face {
        font-family: 'roboto';
        src: url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
        font-display: swap;
    }
    @font-face {
        font-family: 'poppins';
        src: url('assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
        font-display: swap;
        font-weight: bold;
    }
    @font-face {
        font-family: 'poppins-regular';
        src: url('assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
        font-display: swap;
        font-weight: bold;
    }
    @font-face {
        font-family: 'poppins-light';
        src: url('assets/fonts/poppins/Poppins-Light.ttf') format('truetype');
        font-display: swap;
        /* font-weight: bold; */
    }
    @font-face {
        font-family: 'varela-round';
        src: url('assets/fonts/varela-round/VarelaRound-Regular.ttf') format('truetype');
        font-display: swap;
        /* font-weight: bold; */
    }
    @font-face {
        font-family: 'trueno';
        src: url('assets/fonts/trueno/TruenoRg.otf') format('opentype');
        font-display: swap;
        /* font-weight: bold; */
    }
    :root {
        --primary: #75C6EF;
        --primary-hover: #75C6EF;
        --white: #ffffff;
        --grey: #575757;
        --softgrey: lightgrey;
        --smoke: whitesmoke;
        --navy: #1B4674;
        --navy-hover: #6893c4;
        --red: #CD4051;
        --cream: #FCE8CE;
        --cream-bg: #FEF8F0;
        --softcream: #F5F4F4;
    }
    *{
        box-sizing: border-box;
    }
    html{
        scroll-behavior: smooth;
        display: flex;
        justify-content: center;
        font-size: 16px;
    }
    body{
        width: 100%;
        margin: 0;
        font-family: poppins-regular;
        font-size: 1rem;
        min-width: 250px;
        max-width: 2500px; /* same as .nav-pad */
    }
    h1, h2, h3, h4, h5, h6{
		font-family: poppins;
	}
    ::-webkit-scrollbar{
        width: 0.5rem;
    }
    ::-webkit-scrollbar-thumb{
        background: var(--primary);
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    input:-webkit-autofill{
        -webkit-text-fill-color: var(--grey) !important;
    }
    /* .bt-main{
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border: none;
        border-radius: 5px;
        background: var(--primary);
        color: var(--white);
        transition: 0.2s;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }
    .bt-main:hover{
        background: var(--primary-hover);
    } */
    .link-navy a{
        color: var(--navy);
    }
    .link-white a{
        color: var(--white);
    }
    .section-bg-cream{
        background: var(--cream-bg);
    }
    .section-bg-red{
        background: var(--red);
    }
    .section-bg-white{
        background: var(--white);
    }
    .section-color-navy{
        color: var(--navy);
    }
    .section-color-white{
        color: var(--white);
    }
    .color-green{
        color: green;
    }
    .color-red{
        color: red;
    }
    /* .waiting-process{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: pulse-bg-transparent 1s infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--grey);
        z-index: 15;
    } */
    .button-main a{
        background: #FCE8CE;
        color: #575757;
        padding: 0.75rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 2rem;
        text-decoration: none;
    }
    /* .content{
        margin: auto;
        padding-top: 4.81rem;
        min-height: 500px;
    } */
    @media print {
        .no-print{
            display: none;
        }
    }
    @keyframes pulse-bg{
        0%{
            background-color: #eee;
        }
        50%{
            background-color: #e0e0e0;
        }
        100%{
            background-color: #eee;
        }
    }
    @keyframes pulse-bg-transparent{
        0%{
            background-color: rgba(238, 238, 238, 0.658);
        }
        50%{
            background-color: #e0e0e065;
        }
        100%{
            background-color: rgba(238, 238, 238, 0.548);
        }
    }
    @keyframes pulse-bg-white{
        0%{
            background-color: #eee;
        }
        50%{
            background-color: #ffffff;
        }
        100%{
            background-color: #eee;
        }
    }
    @keyframes pulse-bg-grey{
        0%{
            background-color: rgb(160, 157, 157);
        }
        50%{
            background-color: #a1a1a1;
        }
        100%{
            background-color: rgb(160, 157, 157);
        }
    }
    @keyframes pulse-bg-navy{
        0%{
            background-color: #a1c1e2;
        }
        50%{
            background-color: #1B4674;
        }
        100%{
            background-color: #a1c1e2;
        }
    }
    @keyframes pulse-bg-bluesky{
        0%{
            background-color: #cceeff;
        }
        50%{
            background-color: #75C6EF;
        }
        100%{
            background-color: #cceeff;
        }
    }
    @keyframes pulse-bg-red{
        0%{
            background-color: var(--red);
        }
        50%{
            background-color: #9b656e;
        }
        100%{
            background-color: var(--red);
        }
    }
    @-webkit-keyframes animatenlloption {
        from {top:200%; opacity:0}
        to {top:75%; opacity:1}
    }
    @keyframes animatenlloption {
        from {top:200%; opacity:0}
        to {top:75%; opacity:1}
    }
    @-webkit-keyframes animatenotification {
        from {right:-100%; opacity:0}
        to {right:10%; opacity:1}
    }
    @keyframes animatenotification {
        from {right:-100%; opacity:0}
        to {right:10%; opacity:1}
    }
    /* .modal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00000070;
        z-index: 20;
        display: none;
        padding-top: 5%;
        text-align: center;
    } */
    .modal-close{
        position: absolute;
        top: 0;
        right: 0;
        /* display: flex; */
        color: var(--grey);
        background: var(--white);
        padding: 1rem;
        /* padding-right: 2rem; */
        z-index: 15;
        /* margin: 1rem; */
        /* margin-right: 2rem; */
        /* width: 27px; */
        /* height: 27px; */
        /* border-radius: 5px; */
        /* justify-content: center; */
        /* align-items: center; */
        cursor: pointer;
        transition: 0.2s;
    }
    .modal-close:hover{
        color: var(--primary-hover);
    }
    .modal-content{
        position: relative;
        width: 75%;
        /* min-height: 50%; */
        height: 90%;
        /* max-height: 90%; */
        /* overflow: auto; */
        background: var(--white);
        padding: 1rem;
        margin-left: 12.5%;
        border-radius: 5px;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;
        transition: 0.2s;
        /* border: 1px solid green; */
    }
    .modal-content::-webkit-scrollbar-thumb{
        border-radius: 10px;
    }
    @media only screen and (max-width: 1500px){
        .modal-content{
            width: 90%;
            margin-left: 5%;
            /* border-radius: 0; */
        }
        /* .modal-content::-webkit-scrollbar-thumb{
            border-radius: 0;
        } */
    }
    @media only screen and (max-width: 750px){
        .modal{
            /* background: var(--white); */
            padding-top: 0;
        }
        .modal-close{
            /* color: var(--grey); */
            /* background: transparent; */
        }
        .modal-content{
            width: 100%;
            height: 100%;
            margin-left: 0;
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
        }
        .modal-content::-webkit-scrollbar-thumb{
            border-radius: 0;
        }
    }
    /* navbar */
    .navbar{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 4.81rem;
        display: flex;
        border: 1px solid whitesmoke;
        font-family: roboto;
        font-weight: 600;
        background: var(--white);
        z-index: 16;
        white-space: nowrap;
        user-select: none;
        transition: 0.2s;
        justify-content: center;
    }
    .nav-pad{
        width: 100%;
        max-width: 2500px; /* same as body */
        padding: 0.5rem 10% 0.5rem 10%;
        /* padding-top: 0.5rem;
        padding-left: 10%;
        padding-right: 10%; */
        transition: 0.2s;
    }
    .navbar-inner{
        display: flex;
        flex-wrap: nowrap;
        text-align: center;
        /* width: 100%; */
        height: 100%;
        /* border: 1px solid green; */
    }
    .navbar-logo{
        width: 36%;
        /* height: 100%; */
        text-align: left;
        transition: 0.2s;
    }
    .navbar-logo a{
        text-decoration: none;
        color: #79b7f1;
    }
    .navbar-logo img{
        height: 100%;
    }
    .navbar-logo-img{
        height: 100%;
        /* border: 1px solid yellow; */
    }
    .navbar-logo-text{
        font-style: italic;
        display: none;
    }
    .navbar-menu{
        width: 49%;
        /* height: 50%; */
        padding-top: 0.7rem;
        transition: 0.2s;
        /* overflow: hidden; */
        /* background: var(--white); */
        z-index: 15;
        /* border: 1px solid green; */
    }
    .navbar-menu-bg{
        position: fixed;
        top: 0;
        left: 110%;
        width: 0;
        height: 150%;
        background: #000000;
        opacity: 0.5;
        z-index: 10;
    }
    .navbar-login-form{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 150%;
        background: #58585844;
        z-index: 15;
        display: none;
        padding-top: 5rem;
        font-family: poppins;
    }
    .nlf-inner{
        position: relative;
        width: 37%;
        min-height: 370px;
        background: var(--white);
        margin: auto;
        border-radius: 10px;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;
        transition: 0.2s;
    }
    @-webkit-keyframes animatetop {
        from {top:-300px; opacity:0} 
        to {top:0; opacity:1}
    }
    @keyframes animatetop {
        from {top:-300px; opacity:0}
        to {top:0; opacity:1}
    }
    .ni-head{
        position: relative;
        height: 2rem;
        z-index: 5;
    }
    .nlf-content{
        display: flex;
        width: 100%;
        overflow: hidden;
        text-align: left;
        scroll-behavior: smooth;
        z-index: 1;
    }
    .nlf-inner h2{
        text-align: center;
        margin: 0;
        margin-bottom: 1rem;
    }
    .nlf-part{
        position: relative;
        min-width: 100%;
        width: 100%;
        padding: 1%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .nlf-close{
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem;
        cursor: pointer;
        transition: 0.2s;
    }
    .nlf-close:hover{
        color: var(--primary-hover);
    }
    .nlf-input{
        position: relative;
        height: 2rem;
        border-bottom: 1px solid lightgrey;
        /* border-radius: 5px; */
        margin-bottom: 1.5rem;
        text-align: left;
    }
    .nlf-input input{
        height: 100%;
        width: 90%;
        border: none;
        outline: none;
        font-size: 1rem;
        color: #575757;
    }
    .nlf-label{
        position: absolute;
        top: 0;
        left: 0;
        background: var(--white);
        color: #575757;
        font-size: 0.9rem;
        padding: 1%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 100%;
        width: 100%;
        transition: 0.2s;
        text-align: left;
        border-radius: 5px;
        cursor: text;
    }
    .nlf-button{
        text-align: center;
        font-weight: 300;
        margin-bottom: 2rem;
    }
    .nlf-button-text{
        padding: 1%;
        padding-left: 5%;
        padding-right: 5%;
        background: var(--primary);
        color: var(--white);
        /* border-radius: 10px 0 10px 0; */
        cursor: pointer;
    }
    .nlf-button-text:hover{
        background: var(--primary-hover);
    }
    .nlf-link{
        color: var(--primary);
        text-decoration: none;
    }
    .nm-inner{
        display: flex;
        flex-wrap: nowrap;
        background: var(--white);
    }
    .nm-part{
        padding: 0.5rem;
        width: auto;
        margin: auto;
    }
    .nm-part-link{
        text-decoration: none;
        color: #575757;
        transition: 0.2s;
    }
    .nm-part-link:hover{
        color: var(--primary);
    }
    .nm-part-arrow{
        position: relative;
        /* border: 1px solid green; */
        display: flex;
        justify-content: center;
        color: var(--grey);
    }
    .npa-button{
        display: flex;
        /* border: 1px solid green; */
        width: 100%;
        font-size: 0.9rem;
    }
    .npa-button-text{
        padding: 0.1rem 0 0 0;
    }
    .npa-button-icon{
        padding: 0 0.5rem 0 0.5rem;
        /* border: 1px solid green; */
    }
    .npa-button-icon img{
        transition: 0.2s;
    }
    .npa-option{
        position: absolute;
        top: 100%;
        padding: 1rem 0 0 0;
        -webkit-animation-name: animatenpaoption;
        -webkit-animation-duration: 0.2s;
        animation-name: animatenpaoption;
        animation-duration: 0.2s;
        display: none;
        transition: 0.2s;
    }
    .npa-option-active{
        display: block;
    }
    .npa-option-inner{
        min-width: 150px;
        width: auto;
        background: var(--white);
        padding: 0.5rem 0 0.5rem 0;
        border-radius: 5px;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
    }
    @-webkit-keyframes animatenpaoption {
        from {top:200%; opacity:0}
        to {top:100%; opacity:1}
    }
    @keyframes animatenpaoption {
        from {top:200%; opacity:0}
        to {top:100%; opacity:1}
    }
    .npa-option-button{
        text-align: left;
        padding: 0.5rem;
    }
    .npa-option-button a{
        display: block;
        text-decoration: none;
        color: var(--grey);
    }
    .router-link-active{
        color: var(--primary);
    }
    .cart-num{
        color: red;
        font-size: 0.75rem;
    }
    .cart-product{
        color: green;
        font-size: 0.75rem;
    }
    .nm-mobile{
        display: none;
        padding-top: 2rem;
    }
    .nm-mobile a{
        text-decoration: none;
        color: var(--grey);
    }
    .nm-mobile span{
        background: #FCE8CE;
        color: #575757;
        padding: 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 5px;
        transition: 0.2s;
    }
    .nm-mobile-close{
        display: none;
    }
    .nm-mobile-loggedin{
        display: none;
        /* overflow: hidden;
        text-overflow: ellipsis; */
    }
    .navbar-button{
        width: 15%;
        text-align: right;
        padding: 1.2rem;
        padding-right: 0;
        transition: 0.2s;
        /* overflow: hidden; */
    }
    .navbar-button a{
        text-decoration: none;
        color: var(--grey);
    }
    .navbar-button span{
        background: #FCE8CE;
        color: #575757;
        padding: 0.75rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 2rem;
        transition: 0.2s;
    }
    .navbar-button span:hover{
        background: #f7ce9a;
    }
    .nb-login-loggedin{
        position: relative;
        /* overflow: hidden;
        text-overflow: ellipsis; */
        /* border: 1px solid green; */
        /* z-index: 100; */
        min-height: 50px;
        cursor: pointer;
    }
    .nll-button{
        background: unset;
        color: #575757;
        /* padding: 0.75rem;
        padding-left: 2rem;
        padding-right: 2rem; */
        border-radius: 0;
        transition: 0.2s;
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        /* cursor: pointer; */
    }
    .nll-button:hover{
        background: var(--white);
    }
    .nll-select{
        position: absolute;
        top: 75%;
        right: 0;
        width: 100%;
        background: var(--white);
        color: var(--grey);
        /* border: 1px solid var(--softgrey); */
        border-radius: 5px;
        padding: 0.5rem;
        text-align: left;
        display: none;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        animation-name: animatenlloption;
        animation-duration: 0.2s;
        transition: 0.2s;
        overflow: hidden;
    }
    .nll-option{
        cursor: pointer;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .nll-option:hover{
        background: var(--smoke);
    }
    .nb-menumob{
        display: none;
        z-index: 100;
    }
    .nb-menumob img{
        max-width: 2rem;
    }
    .stb-active{
        position: fixed;
        top: 5.5rem;
        right: 10%;
        width: 24%;
    }
    .stb-absolute{
        position: absolute;
        right: 0;
        bottom: 1rem;
        width: 100%;
    }
    @media only screen and (min-width: 2501px){
        .nav-pad{
            padding-left: 16rem;
            padding-right: 16rem;
        }
    }
    @media only screen and (max-width: 1500px){
        @-webkit-keyframes animatenotification {
            from {right:-100%; opacity:0}
            to {right:5%; opacity:1}
        }
        @keyframes animatenotification {
            from {right:-100%; opacity:0}
            to {right:5%; opacity:1}
        }
        .nav-pad{
            padding-left: 5%;
            padding-right: 5%;
        }
        .navbar-logo{
            width: 20%;
        }
        /* .navbar-logo img{
            height: 100%;
        } */
        .navbar-menu{
            width: 71%;
        }
        .navbar-button{
            width: 12%;
        }
        .stb-active{
            right: 5%;
            width: 27%;
        }
    }
    @media only screen and (max-width: 1200px){
        .nlf-inner{
            width: 50%;
        }
    }
    /* @media only screen and (max-width: 1145px){
        .navbar{
            font-size: 75%;
        }
    } */
    @media only screen and (max-width: 1050px){
        html{
            display: inline;
        }
        .navbar{
            font-size: 75%;
        }
        .navbar-button span{
            padding: 0.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .stb-active{
            position: relative;
            width: 100%;
            top: 0;
            right: 0;
        }
    }
    @media only screen and (max-width: 750px){
        .nav-pad{
            padding-top: 0.75rem;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .navbar-logo{
            width: 23%;
        }
        .navbar-menu{
            position: fixed;
            top: 0;
            left: 110%;
            width: 75%;
            height: 150%;
            transition: 0.2s;
            text-align: left;
            padding-left: 1%;
            padding-right: 15%;
            font-size: 1rem;
            /* border: 1px solid green; */
            background: var(--white);
        }
        .nm-mobile-close{
            display: block;
            position: absolute;
            top: 1rem;
            right: 4.1%;
            width: 1.5rem;
            height: 1.5rem;
        }
        .nm-mobile-close img{
            width: 100%;
            max-width: 1.5rem;
            height: 100%;
        }
        .navbar-menu-bg{
            left: 0;
        }
        .navbar-login-form{
            padding-top: 0;
        }
        .nlf-inner{
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
        .nm-inner{
            display: inline;
        }
        .nm-part-arrow{
            display: inline;
            justify-content: left;
        }
        .npa-option{
            position: relative;
            display: block;
            height: 0;
            overflow: hidden;
            padding: 0 0 0 0;
        }
        /* .npa-option-active{
            height: auto;
        } */
        .npa-option-inner{
            min-width: unset;
            width: 100%;
            box-shadow: unset;
            padding: 0 0 0 0;
            border: 1px solid var(--softgrey);
            margin: 1rem 0 1rem 0;
        }
        .nm-mobile{
            display: block;
        }
        .nm-mobile-loggedin{
            display: block;
        }
        .nm-mobile img{
            width: 1.5rem;
        }
        .navbar-button{
            width: 77%;
            padding: 1rem;
            padding-top: 0.5rem;
            padding-right: 0;
        }
        .nb-login{
            display: none;
        }
        .nb-login-loggedin{
            display: none;
        }
        .nb-menumob{
            display: block;
        }
    }
    /* content */
    .content{
        padding: 4.81rem 0 0 0;
        min-height: 500px;
    }
    /* footer */
    footer{
        position: relative;
        background: var(--white);
    }
    .footer-top{
        display: flex;
        flex-wrap: wrap;
        padding-left: 10%;
        padding-right: 10%;
        background: #FEF8F0;
        height: 10.25rem;
        transition: 0.2s;
    }
    .staff{
        width: 60%;
        display: flex;
        padding-top: 3.87rem;
        transition: 0.2s;
    }
    .staff-icon{
        position: relative;
        min-width: 100px;
        width: 19%;
        max-width: 110px;
        min-height: 75px;
    }
    .staff-icon img{
        position: absolute;
        width: 55%;
    }
    .staff-icon-phone{
        top: 0;
        left: 0;
        z-index: 1;
    }
    .staff-icon-male{
        top: 0;
        right: 0;
        z-index: 0;
    }
    .staff-text{
        font-weight: 600;
        padding-left: 3.6rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .staff-text p{
        margin: 0;
    }
    .sosign{
		width: 40%;
		display: flex;
		flex-wrap: wrap;
		justify-content: right;
	}
    .socials{
        width: 40%;
        display: flex;
        justify-content: right;
        padding-top: 3.87rem;
        transition: 0.2s;
    }
    .socials-icon{
        margin: 0.5rem;
        height: 1.9rem;
    }
    .socials-icon img{
        height: 100%;
    }
    .signup{
		width: 50%;
		display: flex;
		justify-content: right;
		align-items: center;
	}
	.signup-button button{
		border: none;
		border-radius: 5rem;
		background: var(--navy);
		color: var(--white);
		padding: 0.5rem 1rem 0.5rem 1rem;
		cursor: pointer;
		user-select: none;
	}
	.modal{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #51525369;
		z-index: 17;
		display: none;
	}
	.modal-active{
		display: block;
	}
	.signup-modal{
		padding: 1rem 25% 5rem 25%;
		transition: 0.2s;
	}
	.signup-modal-head{
		position: absolute;
		top: 0;
		right: 0;
		color: var(--white);
		padding: 1rem;
		z-index: 19;
	}
	.smh-close{
		cursor: pointer;
		/* z-index: 19; */
	}
	.signup-modal-body{
		position: relative;
		/* padding: 5rem; */
		/* display: flex; */
		/* justify-content: center; */
		animation-name: animatesignup;
		animation-duration: 0.5s;
		/* border: 1px solid green; */
		transition: 0.2s;
	}
	.smb-inner{
		background: var(--white);
		color: var(--grey);
		min-height: 200px;
		width: 100%;
		padding: 1rem;
		border-radius: 10px;
        /* border: 1px solid green; */
        text-align: left;
	}
	.smb-form-input{
		width: 100%;
		margin-bottom: 1rem;
	}
	.smb-form-input input{
		width: 100%;
        border: none;
        border-bottom: 1px solid #D3D3D3;
        outline: none;
        padding: 1% 0 1% 0;
        color: #575757;
	}
	.smb-form-input button{
		background: var(--navy);
		color: var(--white);
		border: none;
		padding: 0.5rem 1rem 0.5rem 1rem;
		transition: 0.2s;
		cursor: pointer;
		user-select: none;
	}
	.smb-form-input button:hover{
		background: var(--navy-hover);
	}
	.sfi-label{
		font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
	}
	.sfi-select{
        position: relative;
        color: var(--grey);
        font-size: 0.9rem;
        user-select: none;
    }
    .sfi-select-button{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        background: var(--smoke);
        padding: 1rem;
        cursor: pointer;
        user-select: none;
        overflow: hidden;
    }
    .btn-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .ssb-text{
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .float-icon{
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .float-icon img{
        transition: 0.2s;
    }
    .float-icon-active img{
        transform: rotate(180deg);
    }
    .sfi-select-option{
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        min-height: 50px;
		/* max-height: 200px; */
        display: none;
        background: var(--white);
        border: 2px solid var(--smoke);
        overflow: auto;
        z-index: 5;
    }
    .float-active{
        display: block;
    }
	.sso-notif{
		padding: 0.5rem 1rem 0.5rem 1rem;
		color: var(--primary);
		font-size: 0.75rem;
		font-style: italic;
	}
	.sso-button-list{
		max-height: 200px;
		overflow: auto;
	}
    .sso-button{
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 1rem;
        transition: 0.2s;
        cursor: pointer;
        user-select: none;
    }
    .sso-button:hover{
        background: var(--smoke);
    }
	.sso-button-daytime{
		cursor: auto;
		height: auto;
	}
	.sso-button-daytime:hover{
		background: var(--white);
	}
	.sso-button-schedule{
		display: flex;
		width: 100%;
		border-bottom: 1px solid var(--softgrey);
	}
	.sbs-day{
		width: 30%;
	}
	.sbs-clock{
		width: 70%;
		text-align: center;
	}
	.sbs-clock-list{
		/* cursor: pointer; */
		/* background: var(--primary); */
		/* color: var(--white); */
		padding: 0.5rem;
	}
	.scl-inner{
		cursor: pointer;
		background: var(--primary);
		color: var(--white);
		padding: 0.5rem;
		transition: 0.2s;
	}
	.scl-inner:hover{
		background: var(--primary-hover);
	}
	.scl-inner-appointment{
		font-style: italic;
		font-size: 0.75rem;
	}
    .sso-note{
        margin: 1rem 0 1rem 0;
        text-align: center;
        font-size: 1rem;
        font-family: roboto;
        font-weight: 300;
        font-style: italic;
        color: var(--softgrey);
    }
    .footer-middle{
        display: flex;
        flex-wrap: wrap;
        height: 14.69rem;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
        transition: 0.2s;
    }
    .fm-logo{
        width: 40%;
        padding-top: 5.94rem;
        transition: 0.2s;
    }
    .fm-logo img{
        width: 100%;
    }
    .fm-text{
        display: flex;
        flex-wrap: wrap;
        width: 60%;
        padding-top: 5.37rem;
        color: #1C4677;
        transition: 0.2s;
    }
    .fm-office{
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        transition: 0.2s;
    }
    .fm-contact{
        width: 50%;
        transition: 0.2s;
    }
    .fm-text h2{
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
        margin-bottom: 1rem;
    }
    .fm-text p{
        margin: 0;
        margin-top: 0.2rem;
        font-weight: 400;
        font-size: 1rem;
        transition: 0.2s;
    }
    .footer-bottom{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 8.0625rem;
        padding-left: 10%;
        padding-right: 10%;
        background-image: url('./assets/images/background/ftbt-background.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transition: 0.2s;
    }
    .footer-bottom p{
        font-size: 0.9rem;
        transition: 0.2s;
    }
    .fb-left{
        width: 50%;
        padding-top: 1.25rem;
        color: #1C4677;
        transition: 0.2s;
    }
    .fb-right{
        position: relative;
        display: flex;
        justify-content: center;
        width: 50%;
        transition: 0.2s;
    }
    .fbr-medals{
        margin-top: 2.0625rem;
    }
    .fbr-text{
        margin-top: 2.5rem;
        margin-left: 1.3125rem;
        transition: 0.2s;
        font-size: 0.9rem;
    }
    .fbr-text a{
        text-decoration: none;
        color: #1C4677;
    }
    .cookies-link{
        margin-left: 1rem;
    }
    .notification{
        display: none;
        position: fixed;
        top: 100px;
        right: 10%;
        background: rgb(76, 155, 230);
        color: var(--white);
        min-width: 200px;
        min-height: 50px;
        border-radius: 5px;
        padding: 0.5rem;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        user-select: none;
        animation-name: animatenotification;
        animation-duration: 0.5s;
        z-index: 100;
    }
    .chat-box{
        position: fixed;
        right: 10%;
        bottom: 5%;
        /* width: 370px; */
        /* height: 75px; */
        /* overflow: hidden; */
        background: var(--white);
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        /* padding: 0.5rem; */
        border-radius: 10px;
        z-index: 15;
        transition: 0.2s;
    }
    .chatbox-active{
        height: 500px;
    }
    .chat-box-button{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        height: 75px;
        width: 370px;
    }
    .cbb-chaticon{
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
    }
    .cbb-chaticon img{
        width: 100%;
    }
    .cbb-text{
        width: 75%;
        text-align: center;
    }
    .cbb-icon{
        width: 10%;
        text-align: center;
    }
    .cbb-icon img{
        /* transition: 0.2s; */
    }
    .chat-box-body{
        /* height: 450px; */
        width: 370px;
        overflow: hidden;
        /* transition: 0.2s height ease-in-out; */
        display: none;
    }
    .cbody-active{
        display: block;
        /* height: auto; */
    }
    .chat-box-stage{
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        /* border: 1px solid green; */
        border-top: 1px solid var(--softgrey);
        height: 450px;
    }
    .cbs-part{
        height: 100%;
    }
    .chat-box-select{
        min-width: 100%;
        padding: 0.5rem;
        /* border-top: 1px solid var(--softgrey); */
    }
    .chat-box-option{
        margin-bottom: 0.5rem;
        cursor: pointer;
        transition: 0.2s;
        padding: 0.5rem;
        user-select: none;
    }
    .chat-box-option:hover{
        background: var(--smoke);
    }
    .cbs-doctor{
        min-width: 100%;
        height: 100%;
        overflow: hidden;
        /* border: 1px solid red; */
    }
    .cbs-doctor-inner{
        padding: 0.5rem;
        height: 100%;
        overflow: auto;
        background: var(--primary);
        /* background-image: url('../src/assets/images/background/chatscreenbg.webp'); */
        background-image: url('../src/assets/images/background/chatscreenbg.jpg');
        /* border: 1px solid green; */
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .cbs-doctor-head{
        height: 7.5%;
        /* border: 1px solid navy; */
        /* border-bottom: 1px solid var(--softgrey); */
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .cbs-doctor-body{
        /* min-height: 700px; */
        height: 92.5%;
        /* border: 1px solid red; */
        /* border-radius: 10px; */
        /* padding: 0.5rem; */
    }
    .cbs-back{
        cursor: pointer;
    }
    .cbs-back img{
        transform: rotate(90deg);
    }
    .cdb-inner{
        height: 100%;
    }
    .cdb-tab{
        /* border: 1px solid brown; */
        height: 100%;
    }
    .cdb-tab-head{
        display: flex;
        /* border: 1px solid gold; */
        height: 15%;
        overflow: auto;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        align-items: center;
    }
    .cdb-tab-head::-webkit-scrollbar{
        display: none;
    }
    .cth-inner{
        min-width: 100px;
        /* width: 100px; */
        height: 37px;
        /* border: 1px solid var(--softgrey); */
        border-radius: 5px;
        padding: 0.15rem;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        margin-right: 0.5rem;
        cursor: pointer;
        user-select: none;
        background: var(--white);
        color: var(--grey);
        /* transition: 0.2s; */
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
    }
    /* .cth-inner:hover{
        background: var(--primary-hover);
        color: var(--white);
    } */
    .cth-inner-active{
        background: var(--primary);
        color: var(--white);
        /* box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15); */
    }
    .cth-inner-text{
        /* width: 100%; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        /* padding: 0.15rem;
        padding-left: 1rem;
        padding-right: 1rem; */
        /* display: flex;
        align-items: center; */
        /* font-size: 0.9rem;
        margin-right: 0.5rem;
        cursor: pointer;
        user-select: none;
        border: 1px solid var(--softgrey);
        border-radius: 5px; */
    }
    /* .cit-active{
        background: var(--grey);
        color: var(--white);
    } */
    .cdb-tab-body{
        display: flex;
        /* padding-top: 0.5rem; */
        /* border: 1px solid green; */
        height: 85%;
    }
    .ctb-inner{
        position: relative;
        display: none;
        /* padding: 0.5rem; */
        padding-top: 0.5rem;
        /* border: 1px solid green; */
        height: 100%;
        min-width: 100%;
    }
    .ctb-inner-active{
        display: block;
    }
    .ctb-screen{
        height: 75%;
        /* border: 1px solid green; */
        /* border-bottom: none; */
        padding: 0.5rem;
        overflow: auto;
        scroll-behavior: smooth;
    }
    .ctb-screen-board{
        display: flex;
    }
    .csboard-you{
        justify-content: right;
        text-align: left;
    }
    .csboard-they{
        justify-content: left;
        text-align: right;
    }
    .csboard-inner{
        background: var(--white);
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 5px 0 rgba(0, 0, 0, 0.15);
        border: 1px solid var(--smoke);
        border-radius: 5px;
        margin-top: 0.5rem;
        /* margin-bottom: 0.5rem; */
        padding: 0.5rem;
        overflow-wrap: break-word;
        min-width: 37%;
        /* width: 90%; */
        max-width: 90%;
    }
    .csb-sender{
        font-size: 0.75rem;
        font-style: italic;
        /* color: #068f5b; */
    }
    .csb-sender-you{
        color: rgb(14, 147, 209);
    }
    .csb-sender-they{
        color: rgb(126, 199, 159);
    }
    .csb-message{
        font-size: 0.9rem;
        color: var(--grey);
    }
    /* .csb-message-you{
        text-align: left;
    }
    .csb-message-they{
        text-align: right;
    } */
    .csb-report{
        /* text-align: right; */
    }
    .csb-report-you{
        text-align: right;
    }
    .csb-report-they{
        text-align: left;
    }
    .csb-sender-time{
        font-size: 0.67rem;
        color: var(--softgrey);
    }
    .csb-sender-check{
        font-size: 0.75rem;
        color: var(--grey);
        padding-left: 0.5rem;
    }
    .csc-clock img{
        max-width: 10px;
    }
    .csc-one img{
        max-width: 10px;
    }
    .ctb-field{
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        height: 15%;
        width: 100%;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 1rem;
        /* background: var(--white); */
        /* border: 1px solid green; */
    }
    .ctb-field p{
        margin: 0;
        font-size: 0.9rem;
    }
    .ctb-field-input{
        width: 85%;
        /* padding: 0.5rem; */
        padding-right: 0.5rem;
        /* border: 1px solid var(--softgrey); */
    }
    .cfi-inner{
        height: 100%;
        width: 100%;
        border: 1px solid var(--softgrey);
        border-radius: 100px;
        overflow: hidden;
        padding: 0.5rem;
        background: var(--white);
    }
    .ctb-field-input input{
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding: 1%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .ctb-field-button{
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--grey);
        color: var(--white);
        font-size: 0.9rem;
        cursor: pointer;
        user-select: none;
        padding: 0.5rem;
    }
    .ctb-field-unpaid{
        justify-content: center;
        height: auto;
        padding: 0.5rem;
        background: var(--white);
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .ctb-field-unpaid p{
        font-style: italic;
        color: red;
        text-align: center;
    }
    .ctb-field-paid{
        justify-content: center;
        height: auto;
        padding: 0.5rem;
        background: var(--white);
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .ctb-field-paid p{
        font-style: italic;
        color: navy;
        text-align: center;
    }
    .ctb-field-done{
        justify-content: center;
        height: auto;
        padding: 0.5rem;
        background: var(--white);
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .ctb-field-done p{
        font-style: italic;
        color: navy;
        text-align: center;
    }
    .cfd-inner{
        position: relative;
        height: 100%;
        overflow: hidden;
    }
    .rate-button{
        display: flex;
        justify-content: center;
        padding-top: 0.5rem;
    }
    .rate-button button{
        padding: 0.5rem 1rem 0.5rem 1rem;
        background: var(--primary);
        color: var(--white);
        border: none;
    }
    .rate-modal{
        position: absolute;
        left: 0;
        bottom: -200%;
        width: 100%;
        height: 100%;
        background: var(--white);
        transition: 0.5s;
    }
    .rate-modal-inner{
        position: relative;
    }
    .rate-modal-close{
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        cursor: pointer;
    }
    .rate-modal-star{
        display: flex;
        width: 100%;
        justify-content: center;
        user-select: none;
    }
    .rms-inner{
        display: flex;
        width: 50%;
    }
    .rms-icon{
        position: relative;
        width: 20%;
        height: 50px;
        padding: 0.1rem;
        cursor: pointer;
    }
    .rms-icon img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .rms-icon-silver{
        display: block;
        z-index: 1;
    }
    .rms-icon-gold{
        /* display: none; */
        z-index: 5;
    }
    .rate-modal-input{
        width: 100%;
    }
    .rate-modal-input textarea{
        width: 100%;
        height: 75px;
        outline: none;
        padding: 0.5rem;
        border: 1px solid var(--softgrey);
    }
    .ctb-field-rated{
        justify-content: center;
        height: auto;
        padding: 0.5rem;
        background: var(--white);
        color: var(--grey);
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .cbs-cs{
        min-width: 100%;
        /* height: 370px; */
    }
    .cbs-cs-inner{
        padding: 0.5rem;
        height: 370px;
    }
    .cbs-cs-head{
        height: 15%;
        border-bottom: 1px solid var(--softgrey);
    }
    .cbs-cs-body{
        height: 85%;
        /* border: 1px solid var(--softgrey);
        border-radius: 10px; */
        padding: 0.5rem;
    }
    .link-tree-icon{
        position: fixed;
        right: 50px;
        bottom: 2.5%;
        width: 75px;
        height: 75px;
        background: #ffffff;
        display: flex;
        /* text-align: center; */
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 75px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        z-index: 11;
        overflow: hidden;
        transition: 0.5s;
    }
    .link-tree-icon img{
        width: 100%;
        height: 100%;
        transition: 0.5s;
    }
    .go-top{
        display: flex;
        position: fixed;
        right: 1%;
        bottom: -15%;
        width: 75px;
        height: 75px;
        background: #a1a2a359;
        color: #515253;
        justify-content: center;
        align-items: center;
        z-index: 19;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.2s;
        user-select: none;
    }
    .go-top img{
        transform: rotate(180deg);
    }
    @media only screen and (max-width: 1650px){
        .fm-logo{
            width: 26%;
        }
        .fm-text{
            width: 74%;
        }
    }
    @media only screen and (max-width: 1500px){
        .footer-top{
            padding-left: 5%;
            padding-right: 5%;
        }
        .footer-middle{
            padding-left: 5%;
            padding-right: 5%;
        }
        .footer-bottom{
            padding-left: 5%;
            padding-right: 5%;
        }
        .notification{
            right: 5%;
        }
        .chat-box{
            right: 7.5%;
            /* bottom: 15%; */
        }
        .go-top{
            width: 50px;
            height: 50px;
            right: 1%;
        }
    }
    @media only screen and (max-width: 1015px){
        .footer-top{
            height: auto;
        }
        .staff{
            width: 100%;
        }
        .sosign{
			width: 100%;
			padding: 0 0 1rem 0;
		}
        .socials{
            width: 100%;
            justify-content: left;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        .chat-box{
            right: 10%;
        }
    }
    @media only screen and (max-width: 750px){
        .cbs-doctor-body{
            min-height: 666px;
            /* border: 1px solid green; */
        }
        .staff{
            padding-top: 2.5rem;
        }
        .staff-text{
            padding-left: 5%;
        }
        .footer-top{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .signup-modal{
			padding: 0;
		}
		.smh-close{
			/* position: absolute; */
			color: var(--navy);
			/* z-index: 100; */
		}
		.signup-modal-body{
			height: 100%;
		}
		.smb-inner{
			border-radius: 0;
			height: 100%;
			overflow: auto;
			padding: 2.5rem 1rem 1rem 1rem;
		}
        .footer-middle{
            height: auto;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .fm-logo{
            width: 100%;
            padding-top: 2rem;
        }
        .fm-text{
            width: 100%;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        .fm-text p{
            font-size: 0.9rem;
        }
        .fm-office{
            width: 100%;
            padding-left: 0;
        }
        .fm-contact{
            width: 100%;
            margin-top: 2rem;
        }
        .footer-bottom{
            height: 10rem;
            width: 100%;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .footer-bottom p{
            font-size: 0.75rem;
        }
        .fb-left{
            width: 100%;
            padding-top: 2rem;
        }
        .fb-left p{
            margin: 0;
        }
        .fb-right{
            width: 100%;
            justify-content: left;
        }
        .fbr-text{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            margin-top: 0;
            margin-left: 0;
            font-size: 0.75rem;
        }
        .notification{
            right: 2.5%;
        }
        .chat-box{
            right: unset;
            left: 2.5%;
            z-index: 5;
        }
        .go-top{
            right: 2.5%;
            border-radius: 50px;
        }
    }
    @media only screen and (max-width: 450px){
        .chat-box{
            width: 75px;
            /* right: unset; */
            /* left: 2.5%; */
            bottom: 5%;
            border-radius: 5px;
            transition: unset;
            /* z-index: 5; */
            background: none;
            box-shadow: unset;
        }
        .chatbox-active{
            min-width: 200px;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            border-radius: 0;
            z-index: 17;
            background: var(--white);
        }
        .chat-box-head{
            /* height: 7.5%; */
            width: 100%;
            /* border: 1px solid green; */
        }
        .chat-box-button{
            /* height: auto; */
            width: 100%;
            /* background: var(--white); */
            /* align-items: flex-end; */
            align-items: baseline;
        }
        .cbb-chaticon{
            width: 90%;
            justify-content: right;
            align-items: baseline;
            /* padding: 0; */
            /* padding-top: 0; */
            /* border: 1px solid green; */
        }
        .cbb-chaticon img{
            max-height: 50px;
            width: auto;
        }
        .cbb-text{
            display: none;
            /* width: 70%; */
            /* border: 1px solid green; */
        }
        .cbb-icon{
            display: none;
            /* width: 10%; */
            /* border: 1px solid green; */
        }
        .chat-box-body{
            width: 100%;
            /* height: 85%; */
        }
        .cbody-active{
            height: 92.5%;
        }
        .chat-box-stage{
            height: 100%;
        }
        .cbs-doctor-inner{
            /* height: 100%; */
            /* border: 1px solid green; */
        }
        .cbs-doctor-head{
            /* border: 1px solid green; */
            max-height: 37px;
        }
        .cbs-doctor-body{
            /* border: 1px solid green; */
        }
        .cdb-tab-head{
            /* border: 1px solid green; */
            height: 10%;
            max-height: 50px;
            align-items: unset;
        }
        .cdb-tab-body{
            /* border: 1px solid green; */
            height: 90%;
        }
        .ctb-screen{
            height: 85%;
        }
        .ctb-field{
            /* border: 1px solid green; */
            /* min-height: 45px; */
            height: 15%;
            /* max-height: 50px; */
            align-items: center;
        }
        .ctb-field-unpaid{
            height: auto;
        }
        .ctb-field-paid{
            height: auto;
        }
        /* .ctb-field-done{
            height: 200px;
        } */
        .ctb-field-rated{
            height: auto;
        }
    }
    @media only screen and (max-width: 370px){
        .socials{
			width: 100%;
			padding: 0.5rem 0 0.5rem 0;
		}
		.signup{
			width: 100%;
			justify-content: left;
			padding: 0.5rem 0 0.5rem 0;
		}
    }
    /*  */
</style>