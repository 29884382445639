<template>
    <section class="page-content">
        <TopBackgroundComponent/>
        <DoctorListComponent/>
    </section>
</template>

<script setup>
    import TopBackgroundComponent from '../components/TopBackgroundComponent.vue';
    import DoctorListComponent from '@/components/doctor/DoctorListComponent.vue';
    window.scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>