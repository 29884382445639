<template>
    <section>
        <div class="questionnaire">
            <h1>Skrining Kesehatan Mental Online</h1>
            <p>Teddy Hidayat dr Sp KJ (K)</p>
            <div v-if="currentSlide === 0">
                <p>Skrining kesehatan mental bukan untuk menegakkan diagnosa, tetapi untuk mengidentifikasi target intervensi, memetakan faktor risiko dan kemudian menindaklanjutinya. Misal terditeksi memiliki risiko diabetes, agar terhindar dari penyakit diabetes segera lakukan berbagai upaya preventif. Bila ditemukan sejak awal dapat ditatalaksana lebih baik. Instrument skrining yang digunakan yaitu memuat data umum, riwayat penyakit, stressor psikososial, keluhan psikosomatik  dan DASS 21 yaitu untuk menditeksi gejala gangguan mental emosioal yaitu depresi, cemas dan stres. Untuk mengikuti skrining subyek perlu mengunduh aplikasi pendukung yang telah ditentukan. Dalam tahapan pemeriksaan mengisi kuesioner dan DASS 21. Hasil pemeriksaan yang terekam dalam data medis elektronik akan menjadi acuan untuk merekomendasikan pemeriksaan lanjutan sesuai kebutuhan.</p>
                <h2>Informed Concent</h2>
                <p>
                Dengan mengisi atau mencentang jawaban di bawah ini saya menyadari bahwa kuesioner ini bertujuan untuk Skrining Kesehatan Mental Secara Online. Saya mengisi kuesioner ini secara sadar dan tanpa paksaan. Saya menyetujui untuk terlibat dalam survey ini dan mengizinkan jawaban saya digunakan hanya untuk kepentingan kesehatan mental diri saya sendiri dan kesehatan jiwa komunitas.
                </p>
                <div class="consent-options">
                <label><input type="radio" v-model="consent" value="agree" /> Setuju</label>
                <label><input type="radio" v-model="consent" value="disagree" /> Tidak</label>
                </div>
                <br/>
                <button @click="nextSlide" :disabled="consent !== 'agree'" class="next-button">Lanjutkan ke Kuesioner</button>
            </div>
            <div v-if="currentSlide > 0 && currentSlide <= maxSlides">
                <div v-if="currentSlide === 1">
                    <h2>Data Umum</h2>
                    <div class="form-grid">
                        <label for="tanggal">Tanggal Pengisian:</label>
                        <input type="date" v-model="form.date" required />

                        <label>Nomor Induk Kependudukan:</label>
                        <div class="input-wrapper">
                            <div>
                                <input
                                type="text"
                                v-model="form.nik"
                                placeholder="Masukkan NIK"
                                @input="validateNIK"
                                :class="{ 'error-border': nikError }"
                                />
                            </div>
                            <span class="error-message">{{ nikError }}</span>
                        </div>

                        <label>Jenis Kelamin:</label>
                        <select v-model="form.gender">
                        <option disabled value="">Pilih Jenis Kelamin</option>
                        <option>L</option>
                        <option>P</option>
                        </select>

                        <label>Umur:</label>
                        <input type="number" v-model="form.age" min="1" placeholder="Masukkan Umur"/>

                        <label>Status Marital:</label>
                        <select v-model="form.maritalStatus">
                        <option disabled value="">Pilih Status</option>
                        <option>Menikah</option>
                        <option>Belum Menikah</option>
                        <option>Cerai</option>
                        </select>

                        <label>Pendidikan Terakhir:</label>
                        <select v-model="form.education">
                        <option disabled value="">Pilih Pendidikan Terakhir</option>
                        <option>SMP</option>
                        <option>SMU</option>
                        <option>PT</option>
                        </select>

                        <label>Pekerjaan:</label>
                        <select v-model="form.occupation">
                        <option disabled value="">Pilih Pekerjaan</option>
                        <option>PNS</option>
                        <option>Wiraswasta</option>
                        <option>Bisnis</option>
                        <option>Lainnya</option>
                        </select>
                    </div>
                    <div class="navigation">
                        <button @click="prevSlide" :disabled="currentSlide === 0" class="back-button">Sebelumnya</button>
                        <button @click="nextSlide" :disabled="!isDataValid(1)" class="next-button">Selanjutnya</button>
                    </div>
                </div>
                <div v-if="currentSlide === 2">
                    <h2>Pemeriksaan Fisik</h2>
                    <div class="form-grid">
                        <label>Tinggi Badan (cm):</label>
                        <input type="number" v-model="form.height" min="0" placeholder="Masukkan Tinggi Badan">

                        <label>Berat Badan (kg):</label>
                        <input type="number" v-model="form.weight" min="0" placeholder="Masukkan Berat Badan"/>

                        <label>BMI:</label>
                        <input type="number" min="0" placeholder="Dihitung Otomatis" :value="form.bmi" readonly>
                    </div>
                    <div class="navigation">
                        <button @click="prevSlide" :disabled="currentSlide === 0" class="back-button">Sebelumnya</button>
                        <button @click="nextSlide" :disabled="!isDataValid(2)" class="next-button">Selanjutnya</button>
                    </div>
                </div>
                <div v-if="currentSlide === 3">
                    <h2>Penyakit Kronik</h2>
                    <div class="form-grid">
                        <div v-for="(illness, index) in chronicIllnesses" :key="index" class="form-row">
                        <label>{{ illness.name }}:</label>
                        <select v-model="form.chronicIllness[illness.key]">
                            <option disabled value="" selected>Pilih Satu</option>
                            <option value="yes">Ya</option>
                            <option value="no">Tidak</option>
                            <option value="unknown">Tidak tahu</option>
                        </select>
                        </div>
                    </div>
                    <div class="navigation">
                        <button @click="prevSlide" :disabled="currentSlide === 0" class="back-button">Sebelumnya</button>
                        <button @click="nextSlide" :disabled="!isDataValid(3)" class="next-button">Selanjutnya</button>
                    </div>
                </div>
                <div v-if="currentSlide === 4">
                    <h2>Stresor Psikososial</h2>
                    <div class="form-grid">
                        <div class="form-row fr-border">
                            <label>1. Stres di tempat kerja:</label>
                            <div class="radio-group">
                                <label><input type="radio" v-model="form.psychosocial.stressWork" value="yes" /> Ya</label>
                                <label><input type="radio" v-model="form.psychosocial.stressWork" value="no" /> Tidak</label>
                                <label><input type="radio" v-model="form.psychosocial.stressWork" value="unknown" /> Tidak Tahu</label>
                            </div>
                        </div>
                        <div class="form-row fr-border" v-if="form.maritalStatus !== 'Belum Menikah'">
                            <label>2. Stres perkawinan:</label>
                            <div class="radio-group">
                                <label><input type="radio" v-model="form.psychosocial.stressMarriage" value="yes" /> Ya</label>
                                <label><input type="radio" v-model="form.psychosocial.stressMarriage" value="no" /> Tidak</label>
                                <label><input type="radio" v-model="form.psychosocial.stressMarriage" value="unknown" /> Tidak Tahu</label>
                            </div>
                        </div>
                        <div class="form-row fr-border">
                            <label>3. Stres Finansial:</label>
                            <div class="radio-group">
                                <label><input type="radio" v-model="form.psychosocial.financialStress" value="yes" /> Ya</label>
                                <label><input type="radio" v-model="form.psychosocial.financialStress" value="no" /> Tidak</label>
                                <label><input type="radio" v-model="form.psychosocial.financialStress" value="unknown" /> Tidak Tahu</label>
                            </div>
                        </div>
                    </div>
                    <div class="navigation">
                        <button @click="prevSlide" :disabled="currentSlide === 0" class="back-button">Sebelumnya</button>
                        <button @click="nextSlide" :disabled="!isDataValid(4)" class="next-button">Selanjutnya</button>
                    </div>
                </div>
                <div v-if="currentSlide === 5">
                    <h2>Kebiasaan</h2>
                    <div class="form-grid">
                        <div class="form-row fr-border">
                            <label>1. Olahraga:</label>
                            <div class="radio-group">
                                <label><input type="radio" v-model="form.habits.exercise" value="yes" /> Ya</label>
                                <label><input type="radio" v-model="form.habits.exercise" value="no" /> Tidak</label>
                                <label><input type="radio" v-model="form.habits.exercise" value="unknown" /> Tidak Tahu</label>
                            </div>
                        </div>
                        <div class="form-row fr-border">
                            <label>2. Merokok:</label>
                            <div class="radio-group">
                                <label><input type="radio" v-model="form.habits.smoking" value="yes" /> Ya</label>
                                <label><input type="radio" v-model="form.habits.smoking" value="no" /> Tidak</label>
                                <label><input type="radio" v-model="form.habits.smoking" value="unknown" /> Tidak Tahu</label>
                            </div>
                        </div>
                        <div class="form-row fr-border">
                            <label>3. Tidur larut:</label>
                            <div class="radio-group">
                                <label><input type="radio" v-model="form.habits.lateSleep" value="yes" /> Ya</label>
                                <label><input type="radio" v-model="form.habits.lateSleep" value="no" /> Tidak</label>
                                <label><input type="radio" v-model="form.habits.lateSleep" value="unknown" /> Tidak Tahu</label>
                            </div>
                        </div>
                    </div>
                    <div class="navigation">
                        <button @click="prevSlide" :disabled="currentSlide === 0" class="back-button">Sebelumnya</button>
                        <button @click="nextSlide" :disabled="!isDataValid(5)" class="next-button">Selanjutnya</button>
                    </div>
                </div>
                <div v-if="currentSlide === 6">
                    <h2>DASS 21</h2>
                    <p>
                        Tolong baca setiap kalimat dan pilih angka yang sesuai dengan kondisi Anda
                        selama satu minggu terakhir.
                    </p>
                    <div class="dass-questions">
                        <div v-for="(question, index) in dassQuestions" :key="index" class="form-row fr-border">
                            <label>{{ index + 1 }}. {{ question }}</label>
                            <div class="radio-group">
                                <label><input type="radio" :name="'dass-' + index" v-model="form.dass.answers[index]" :value="0" /> 0</label>
                                <label><input type="radio" :name="'dass-' + index" v-model="form.dass.answers[index]" :value="1" /> 1</label>
                                <label><input type="radio" :name="'dass-' + index" v-model="form.dass.answers[index]" :value="2" /> 2</label>
                                <label><input type="radio" :name="'dass-' + index" v-model="form.dass.answers[index]" :value="3" /> 3</label>
                            </div>
                        </div>
                    </div>
                    <div class="navigation">
                        <button @click="prevSlide" :disabled="currentSlide === 0" class="back-button">Sebelumnya</button>
                        <button @click="nextSlide" :disabled="!isDataValid(6)" class="next-button">Selanjutnya</button>
                    </div>
                </div>
                <template v-if="currentSlide === 7">
                    <h2>Pilih keluhan yang sering (boleh lebih dari satu)</h2>
                    <div class="form-grid">
                        <label>
                            <input
                            type="checkbox"
                            value="Nyeri ulu hati, mual atau muntah dan perut kembung"
                            v-model="form.complaints"
                            />
                            Nyeri ulu hati, mual atau muntah dan perut kembung
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            value="Diare, sakit atau kram perut dan kembung"
                            v-model="form.complaints"
                            />
                            Diare, sakit atau kram perut dan kembung
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            value="Khawatir berlebih menderita penyakit (misal jantung)"
                            v-model="form.complaints"
                            />
                            Khawatir berlebih menderita penyakit (misal jantung)
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            value="Nyeri tubuh tertentu tanpa penyakit mendasari"
                            v-model="form.complaints"
                            />
                            Nyeri tubuh tertentu tanpa penyakit mendasari
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            value="Kelelahan dan keletihan meski tidur cukup"
                            v-model="form.complaints"
                            />
                            Kelelahan dan keletihan meski tidur cukup
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            value="Kulit gatal dan kemerahan (eksim)"
                            v-model="form.complaints"
                            />
                            Kulit gatal dan kemerahan (eksim)
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            value="Sakit kepala tegang atau migraine"
                            v-model="form.complaints"
                            />
                            Sakit kepala tegang atau migraine
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            value="Nyeri dada, punggung dan tulang belakang"
                            v-model="form.complaints"
                            />
                            Nyeri dada, punggung dan tulang belakang
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            value="Sesak nafas mengi (asma)"
                            v-model="form.complaints"
                            />
                            Sesak nafas mengi (asma)
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            value="Kadar gula darah dan kadar kolesterol tinggi, hipertensi disertai lemak berlebihan di pinggang"
                            v-model="form.complaints"
                            />
                            Kadar gula darah dan kadar kolesterol tinggi, hipertensi disertai lemak berlebihan di pinggang
                        </label>
                    </div>
                    <div class="navigation">
                        <button @click="prevSlide" :disabled="currentSlide === 0" class="back-button">Sebelumnya</button>
                    </div>
                </template>
                <div v-if="currentSlide === 8">
                    <div v-html="result.advice"></div>
                    <div class="the-end">
                        <hr/>
                        <span>Selesai</span>
                    </div>
                </div>
                <br/>
                <div class="progress-bar">
                    <div :style="{ width: `${(currentSlide / maxSlides) * 100}%` }" class="progress"></div>
                </div>
                <div v-if="currentSlide === 7" class="submit-container">
                <button @click="submitForm" class="submit-button">Submit</button>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script setup>
    import { ref, watch } from 'vue';
    const currentSlide = ref(0);
    const maxSlides = 8;
    const consent = ref('');
    const form = ref({
        date: '',
        nik: '',
        gender: '',
        age: '',
        maritalStatus: '',
        education: '',
        occupation: '',
        height: '',
        weight: '',
        bmi: '',
        chronicIllness: {
            hypertension: '',
            heart: '',
            diabetes: '',
            adhd: '',
            stroke: '',
        },
        psychosocial: {
            stressWork: '',
            stressMarriage: '',
            financialStress: ''
        },
        habits: {
            exercise: '',
            smoking: '',
            lateSleep: ''
        },
        dass: {
            answers: Array(21).fill(null),
            depression: { score: 0, level: '' },
            anxiety: { score: 0, level: '' },
            stress: { score: 0, level: '' }
        },
        complaints: [],
    });
    const nikError = ref("");
    const result = ref({});
    const chronicIllnesses = [
        { name: 'Hipertensi', key: 'hypertension' },
        { name: 'Jantung', key: 'heart' },
        { name: 'Diabetes', key: 'diabetes' },
        { name: 'ADHD', key: 'adhd' },
        { name: 'Stroke', key: 'stroke' }
    ];
    const dassQuestions = [
        'Saya sulit untuk menenangkan diri.',
        'Saya menyadari mulut saya kering.',
        'Saya tidak pernah mengalami perasaan positif sama sekali.',
        'Saya mengalami kesulitan bernapas.',
        'Saya kesulitan untuk berinisiatif melakukan sesuatu.',
        'Saya cenderung bereaksi berlebihan terhadap sesuatu.',
        'Saya mengalami gemetar.',
        'Saya merasa menggunakan banyak energi untuk gelisah.',
        'Saya mengkhawatirkan situasi yang membuat saya panik.',
        'Saya merasa tidak ada hal baik yang saya tunggu.',
        'Saya mendapati diri saya merasa gelisah.',
        'Saya sulit untuk relaks.',
        'Saya merasa rendah diri dan sedih.',
        'Saya tidak toleran terhadap gangguan pekerjaan saya.',
        'Saya merasa mudah panik.',
        'Saya tidak bisa antusias terhadap apapun.',
        'Saya merasa tidak berharga.',
        'Saya merasa mudah tersinggung.',
        'Saya menyadari reaksi jantung saya tanpa aktivitas fisik.',
        'Saya merasa takut.',
        'Saya merasa bahwa hidup ini tidak berarti.'
    ];
    const calculateScores = () => {
        const answers = form.value.dass.answers;
        let depressionScore = 0;
        let anxietyScore = 0;
        let stressScore = 0;
        depressionScore = [3, 5, 10, 13, 16, 17, 21].reduce((acc, idx) => acc + (answers[idx] || 0), 0);
        anxietyScore = [2, 4, 7, 9, 15, 19, 20].reduce((acc, idx) => acc + (answers[idx] || 0), 0);
        stressScore = [1, 6, 8, 11, 12, 14, 18].reduce((acc, idx) => acc + (answers[idx] || 0), 0);
        form.value.dass.depression.score = depressionScore * 2;
        form.value.dass.anxiety.score = anxietyScore * 2;
        form.value.dass.stress.score = stressScore * 2;
        form.value.dass.depression.level = getLevel(depressionScore * 2, 'depression');
        form.value.dass.anxiety.level = getLevel(anxietyScore * 2, 'anxiety');
        form.value.dass.stress.level = getLevel(stressScore * 2, 'stress');
    };

    const getLevel = (score, type) => {
        let level = '';
        if (type === 'depression') {
            if (score >= 0 && score <= 9) level = 'Normal';
            else if (score >= 10 && score <= 13) level = 'Mild';
            else if (score >= 14 && score <= 20) level = 'Moderate';
            else if (score >= 21 && score <= 27) level = 'Severe';
            else if (score >= 28) level = 'Extreme Severe';
        } else if (type === 'anxiety') {
            if (score >= 0 && score <= 7) level = 'Normal';
            else if (score >= 8 && score <= 9) level = 'Mild';
            else if (score >= 10 && score <= 14) level = 'Moderate';
            else if (score >= 15 && score <= 19) level = 'Severe';
            else if (score >= 20) level = 'Extreme Severe';
        } else if (type === 'stress') {
            if (score >= 0 && score <= 14) level = 'Normal';
            else if (score >= 15 && score <= 18) level = 'Mild';
            else if (score >= 19 && score <= 25) level = 'Moderate';
            else if (score >= 26 && score <= 33) level = 'Severe';
            else if (score >= 34) level = 'Extreme Severe';
        }
        return level;
    };
    watch(() => form.value.dass.answers, calculateScores, { deep: true });
    const nextSlide = () => {
        if (currentSlide.value < maxSlides) currentSlide.value++;
        console.log('Form data:', form.value);
        console.log('Current slide: ', currentSlide);
    };
    const prevSlide = () => {
        if (currentSlide.value > 0) currentSlide.value--;
    };
    const submitForm = () => {
        result.value.advice = generateAdvice(form);
        nextSlide();
        scrollTo(0, 0);
    };
    function isDataValid(slideIndex) {
        let isNIKValid = true;
        switch (slideIndex) {
            case 1:
            isNIKValid = !nikError.value;
                return form.value.date && form.value.nik && form.value.gender && form.value.age && form.value.maritalStatus && form.value.education && form.value.occupation && isNIKValid;
            case 2:
                if(calculateBMI(form.value.weight, form.value.height)){
                    form.value.bmi = calculateBMI(form.value.weight, form.value.height);
                }
                return calculateBMI(form.value.weight, form.value.height);
            case 3:
                return form.value.chronicIllness.hypertension && form.value.chronicIllness.heart && form.value.chronicIllness.diabetes && form.value.chronicIllness.adhd && form.value.chronicIllness.stroke;
            case 4:
                return form.value.psychosocial.stressWork && form.value.psychosocial.financialStress && (form.value.maritalStatus === "Belum Menikah" || form.value.psychosocial.stressMarriage);
            case 5:
                return form.value.habits.exercise && form.value.habits.smoking && form.value.habits.lateSleep;
            case 6:
                return !form.value.dass.answers.includes(null);
            case 7:
                return true;
            default:
                return true;
        }
    }
    function calculateBMI(weight, height) {
        // Memastikan bahwa input weight dan height adalah angka yang valid
        if (isNaN(weight) || isNaN(height) || weight <= 0 || height <= 0) {
            return;
            // return "Input tidak valid";
        }

        // Menghitung BMI: BMI = berat badan (kg) / (tinggi badan (m))^2
        // Mengonversi tinggi badan dari cm ke meter
        const heightInMeters = height / 100;

        const bmi = weight / (heightInMeters * heightInMeters);
        return bmi;

        // Mengkategorikan BMI
        // let category = "";
        // if (bmi < 18.5) {
        //     category = "Underweight";
        // } else if (bmi >= 18.5 && bmi < 24.9) {
        //     category = "Normal weight";
        // } else if (bmi >= 25 && bmi < 29.9) {
        //     category = "Overweight";
        // } else {
        //     category = "Obese";
        // }

        // return { bmi: bmi.toFixed(2), category: category };
    }
    const validateNIK = () => {
        const nikPattern = /^\d{16}$/;
        if (!form.value.nik) {
            nikError.value = "NIK tidak boleh kosong.";
        } else if (!nikPattern.test(form.value.nik)) {
            nikError.value = "NIK harus terdiri dari 16 digit angka.";
        } else {
            nikError.value = "";
        }
    };
    function generateAdvice(form) {
        const { nik, gender, age, maritalStatus, education, occupation, height, weight, bmi, chronicIllness, psychosocial, habits, dass, complaints } = form.value;

        let advice = `
        <h2>Saran</h2>
        <h3>Status</h3>
        `;
        advice += generatePersonalInfo(nik, gender, age, maritalStatus, education, occupation, height, weight);
        advice += generatePhysicalExamAdvice(bmi, psychosocial, habits, dass, );
        advice += generateChronicIllnessAdvice(chronicIllness);
        advice += generatePsychosocialAdvice(psychosocial);
        advice += generateHabitsAdvice(habits);
        advice += generateDassAdvice(dass);
        advice += generateComplaintAdvice(complaints);

        return advice;
    }
    function generatePersonalInfo(nik, gender, age, maritalStatus, education, occupation) {
        return `
            <ul>
                <li><strong>Nomor Induk Kependudukan:</strong> ${nik || "Tidak tersedia"}</li>
                <li><strong>Jenis Kelamin:</strong> ${gender || "Tidak tersedia"}</li>
                <li><strong>Umur:</strong> ${age || "Tidak tersedia"} tahun</li>
                <li><strong>Status Marital:</strong> ${maritalStatus || "Tidak tersedia"}</li>
                <li><strong>Pendidikan Terakhir:</strong> ${education || "Tidak tersedia"}</li>
                <li><strong>Pekerjaan:</strong> ${occupation || "Tidak tersedia"}</li>
            </ul>
            `;
    }
    function generatePhysicalExamAdvice(bmi) {
        let advice = ``;
        if(
            // getBMICategory("Kurus") || 
            getBMICategory(bmi) == "Overweight" || getBMICategory(bmi) == "Obesitas"){
            advice += `<h3>Pemeriksaan Fisik</h3>`;
        }
        // if(getBMICategory(bmi) == "Kurus"){
        //     advice += `
        //     <hr/>
        //     <h4>Kekurangan Berat Badan</h4>
        //     <ol>
        //         <li>xxxxx</li>
        //     </ol>`;
        // }
        if(getBMICategory(bmi) == "Overweight" || getBMICategory(bmi) == "Obesitas"){
            advice += `
            <hr/>
            <h4>KelebihanBerat Badan</h4>
            <ol>
                <li>Konsumsi sayur dan buah</li>
                <li>Batasi  tidur</li>
                <li>Tngkatkan  aktivitas  minimal 30 menit / hari</li>
                <li>Batasi nonton televisi, main komputer dan games</li>
                <li>Batasi konsumsi gula, garam, dan lemak</li>
            </ol>`;
        }
        return advice;
    }
    function generateChronicIllnessAdvice(chronicIllness) {
        let advice = ``;
        if(chronicIllness.hypertension == 'yes' || chronicIllness.heart == "yes" || chronicIllness.diabetes == "yes" || chronicIllness.adhd == "yes" || chronicIllness.stroke == "yes"){
            advice += `
            <hr/>
            <h3>Penyakit Kronik</h3>`;
        }

        if (chronicIllness.hypertension == 'yes') {
            advice += `
            <h4>Hipertensi</h4>
            <ol>
                <li>Kontrol ke klinik dan periksa tekanan darah secara teratur</li>
                <li>Minumlah obat secara teratur, sesuai instruksi dokter</li>
                <li>Berolahraga teratur dan terukur</li>
                <li>Hindari  makanan asin, cepat saji, kaleng dan penyedap</li>
            </ol>
            `;
        }

        if (chronicIllness.heart == "yes") {
            advice += `
            <h4>Jantung Koroner</h4>
            <ol>
                <li>Kontrol ke klinik dan minum obat sesuai instruksi dokter</li>
                <li>Batasi garam dan hindari makanan berlemak (darah tinggi)</li>
                <li>Kurangi karbo hidrat,  pedas dan gorengan (obesitas)</li>
                <li>Hindari nikotin, kopi dan alkohol</li>
            </ol>
            `;
        }
        if (chronicIllness.diabetes == "yes") {
            advice += `
            <h4>Diabetes</h4>
            <ol>
                <li>Periksa kadar gula darah secara terartur</li>
                <li>Kontrol ke klinik  dan mium obat sesuai instruksi dokter</li>
                <li>Berolahraga teratur  dan terukur</li>
                <li>Konsumsi buah, sayur, biji-bijian berserat</li>
                <li>Kurangi daging dan lemak, pilih ikah atau ayam</li>
                <li>Kurangi konsumsi gula dan  garam</li>
                <li>Pilih gandum, cereal dan minyak olive atau bunga matahari</li>
                <li>Hindari rokok dan alcohol</li>
            </ol>
            `;
        }
        if (chronicIllness.adhd == "yes") {
            advice += `
            <h4>ADHD</h4>
            <p>Terapi ADHD dapat meningkatkan kualitas hidup dan performance di tempat  kerja</p>
            <p>Pengobatan ADHD / ADD antara lain</p>
            <ol>
                <li>Insight of Illness tentang penyakitnya</li>
                <li>Tidur teratur 8 jam, siang jangan tertidur</li>
                <li>Pola hidup sehat</li>
                <li>Makanan sehat dan gizi seimbang</li>
                <li>Olahraga rutin minimal dua kali dalam seminggu</li>
                <li>Berlatih manajemen waktu</li>
                <li>Merubah pola pikir dan perilaku negatif</li>
                <li>Minum obat sesuai anjuran dokter</li>
            </ol>
            `;
        }
        if (chronicIllness.stroke == "yes") {
            advice += `
            <h4>Stroke</h4>
            <ol>
                <li>Makan banyak sayur dan buah</li>
                <li>Kurangi asin, manis dan gurih</li>
                <li>Olahraga secara teratur misal aerobic seminggu 3 kali</li>
                <li>Kontrol ke klinik  dan minum obat sesuai instruksi dokter</li>
            </ol>
            `;
        }
        return advice;
    }
    function generatePsychosocialAdvice(psychosocial) {
        let advice = ``;
        if(psychosocial.stressWork == "yes" || psychosocial.stressMarriage =="yes" || psychosocial.financialStress == "yes"){
            advice += `
            <hr/>
            <h3>Stresor Psikososial</h3>`;
        }

        if (psychosocial.stressWork == "yes") {
            advice += `
            <h4>Stres di Tempat Kerja</h4>
            <ol>
                <li>Kenali batas kemampuan sehingga dapat mengoptimalkan waktu ; menghindari kelelahan berlebihan</li>
                <li>Sharing dengan rekan kerja   dapat menciptakan suasana kerja yang lebih positif dan lebih nyaman</li>
                <li>Lupakan pekerjaan sejenak membuat pikiran dan tenaga  pulih, seperti menonton film, karaoke, berkebun</li>
                <li>Jaga pola hidup, cukup istirahat, konsumsi vitamin dan makanan bergizi</li>
                <li>Berolahraga dapat melindungi kesehatan fisik dan mental</li>
                <li>Mindfulness: duduk posisi nyaman  benafas biasa, fokus pada setiap tarikan dan hembusan nafas</li>
            </ol>
            `;
        }
        if (psychosocial.stressMarriage =="yes") {
            advice += `
            <h4>Konflik Perkawinan</h4>
            <p>Konflik perkawinan biasanya terjadi karena masalah komunikasi, beda pendapat, kekerasan atau kehidupan seksual. Konflik perkawinan puncaknya pada usia perkawinan 5 tahun dan 20 tahun, salah satu penyebabnya sering karena  kebutuhan pasangan tak terpenuhi (kehausan dan mudah tergoda) Kegagalan memenuhi kebutuhan pasangan biasanya karena dirinya hanya mengerti kebutuhan dirinya sendiri dan memperlakukan pasangannya seperti kebutuhan dirinya</p>
            `;
            if(form.value.gender === "L"){
                advice +=`
                <h5>Suami Idaman</h5>
                <ol>
                    <li>Ucapankan pada istri / pasangan   sayang atau cinta,  beri hadiah dihari ulang tahun</li>
                    <li>Ngobrol dengan istri / pasangan , dengarkan dan beri respon</li>
                    <li>Ceritakan pada istri / pasangan  kondisi Anda, jangan membuat mereka  kaget</li>
                    <li>Bertanggung jawab terhadap istri / pasangan; biayai, jangan tergantung</li>
                    <li>Mengasuh / mendidik, belajar / olahraga dengan anak</li>
                </ol>
                `;
            }
            if(form.value.gender === "P"){
                advice +=`
                <h5>Istri Idaman</h5>
                <ol>
                    <li>Menjadi istri / pasangan yang menggairahkan (seks)</li>
                    <li>Kebersamaan dalam hobi dengan suami / pasangan</li>
                    <li>Buat fisik atraktif dengan diet, olahraga menjaga  penampilan</li>
                    <li>Menjaga kerapihan/kenyamanan rumah dan domestik rumah tangga</li>
                    <li>Jaga harga dirinya suami / pasangan   bila perlu beri , pujian dan respek, hindari mengeritik</li>
                </ol>
                `;
            }
        }
        if (psychosocial.financialStress == "yes") {
            advice += `
            <h4>Keterbatasan finansial</h4>
            <ol>
                <li>Mengelola keuangan lebih bijak</li>
                <li>Buat perencanaan keuangan keluarga</li>
                <li>Terapkan hidup hemat dan sederhana</li>
                <li>Mencari tambahan dengan kerja lembur atau usaha tambahan</li>
                <li>Tarik tunai kartu kredit</li>
                <li>Punya BPJS untuk jaminan berobat</li>
            </ol>
            `;
        }
        // advice += ``;
        return advice;
    }
    function generateHabitsAdvice(habits) {
        let advice = ``;
        if(habits.exercise == "no" || habits.smoking == "yes" || habits.lateSleep == "yes"){
            advice += `
            <hr/>
            <h3>Kebiasaan</h3>`;
        }

        if (habits.exercise == "no") {
            advice += `
            <h4>Olahraga</h4>
            <p>Olahraga membantu menurunkan tekanan darah, kolesterol dan menjaga kebugaran firik serta bahagia.  Untuk yang jarang / tidak pernah  olahraga,  disarankan  olah raga teratur   misal aerobik 3 kali seminggu, olahraga dilakukan rutin setiap hari selama 30-60 menit.</p>
            <p>Cara Olahraga yang Baik dan Benar:</p>
            <ol>
                <li>Kondisi tubuh harus fit</li>
                <li>Pilih waktu yang tepat</li>
                <li>Lakukan pemanasan dan pendinginan</li>
                <li>Sesuaikan jenis olahraga dengan kemampuan</li>
                <li>Perhatikan asupan makanan sebelum olahraga</li>
                <li>Minum air putih yang cukup</li>
            </ol>
            `;
        }
        if (habits.smoking == "yes") {
            advice += `
            <h4>Merokok</h4>
            <ol>
                <li>Untuk yang merokok  segera menghentikan merokoknya dan yang tidak merokok jangan mencobanya</li>
                <li>Langkah pertama adalah niat untuk berhenti merokok</li>
                <li>Kenali dalam situasi apa Anda merokok, gantilah dengan kegiatan positif lain</li>
                <li>Mintalah dukungan dari keluarga dan kerabat</li>
                <li>Tahan keinginan merokok dengan dengan menunda</li>
                <li>Teratur berolahraga</li>
                <li>Konsultasi dengan dokter</li>
            </ol>
            `;
        }
        if (habits.lateSleep == "yes") {
            advice += `
            <h4>Tidur lambat</h4>
            <ol>
                <li>Buat suasana kamar menjadi nyaman</li>
                <li>Rangsang rasa kantuk dengan membaca buku ataupun mendengarkan lagu</li>
                <li>Cukupi asupan cairan untuk 8 gelas  air putih / hari untuk mencegah dehidrasi</li>
                <li>Perbanyak buah dan sayur  dan hindari konsumsi kafein</li>
                <li>Rutin berolahraga dan mengelola stres</li>
            </ol>
            `;
        }
        return advice;
    }
    function generateDassAdvice(dass) {
        let advice = ``;
        if(dass.depression.score >= 10 || dass.anxiety.score >= 8 || dass.stress.score >= 15){
            advice += `
            <hr/>
            <h3>DASS 21</h3>`;
        }

        if (dass.depression.score >= 10) {
            advice += `
            <h4>Mengatasi Depresi</h4>
            <ol>
                <li>Depresi adalah penyakit  dapat dicegah dan diobati, bukan karena kelemahan</li>
                <li>Pengobatan menghilangkan gejala, risiko suicide dan meningkatkan quality of life</li>
                <li>Penderita dapat pulih, fungsi membaik  dan  bertahan hidup sehat</li>
                <li>Antidepresan diminum setiap hari, perbaikan mulai minggu ke 2 – 3, efek sampig ringan berkurang setelah 7-10 hari</li>
                <li>Lawan rasa pesimis dan hindari mengkritik diri sendiri</li>
                <li>Atasi perasaan sepi / bosan dengan menjalin komunikasi</li>
                <li>Olahraga, relaksasi, dengar musik, nonton   perawatan diri dll.</li>
            </ol>
            `;
        }
        if (dass.anxiety.score >= 8) {
            advice += `
            <h4>Mengatasi Cemas</h4>
            <ol>
                <li>Pengobatan gangguan cemas adalah dengan SSRI, meskipun  dalam 2 – 4 minggu sudah menunujukkan perbaikan namun pengobatan harus terus selama 3 – 6 bulan untuk mencegah kekambuhan</li>
                <li>Benzodiazepine dengan dosis rendah, tingkatkan bertahap, lama pengobatan    2  -  6 mg, dilanjutkan “tapering off”  selama 1 - 2 minggu</li>
                <li>Psikoterapi, kognitif behavior terapi, relaksasi dan biofeedbacki</li>
                <li>Distraksi  dengan melihat pemandangan alam, menderngar suara  musik dsb.</li>
                <li>Latihan relaksasi; lakukan dua kali sehari di  tempat yang tenang</li>
                <li>Berbaring atau duduk dalam posisi nyaman, pejamkan mata perlahan Setelah 10 detik konsentrasikan pada pernafasan tarik nafas perlahan satu... dua … tiga, hembuskan nafas perlahan satu dua…tiga (ucapkan rileks.. rileks atau untuk umat Islam “Allah”, umat Kristen Puji Tuhan”  atau umat Hindu “Om”, Terus lakukan berulang kali bernafas dalam  secara   teratur   melalui hidung</li>
            </ol>
            `;
        }
        if (dass.stress.score >= 15) {
            advice += `
            <h4>Mengatasi Stres</h4>
            <ol>
                <li>Bicarakan keluhan dengan seseorang yang dapat dipercaya</li>
                <li>Melakukan kegiatan yang sesuai dengan minat dan kemampuan</li>
                <li>Kembangkan hobi yang bermanfaat</li>
                <li>Meningkatkan ibadah dan mendekatkan diri pada Tuhan</li>
                <li>Mengatasi stres adalah pengambilan keputusan</li>
                <li>Atasi keluhan stres dengan ansiolitik atau antidepresan</li>
                <li>Kontrol dan minum obat sesuai instruksi dokter</li>
            </ol>
            `;
        }
        return advice;
    }
    function generateComplaintAdvice(complaints) {
        let advice = ``;
        if(complaints.length){
            advice += `
            <hr/>
            <h3>Keluhan</h3>`;
        }
        if (complaints.includes("Nyeri ulu hati, mual atau muntah dan perut kembung")) {
            advice += `
            <h4>Dispepsi Fungsional</h4>
            <ul>
                <li>Makan porsi kecil 5 – 6 kali, kunyah sebelum ditelan, hindari pedas, lemak, soda, cafein dan  alkohol</li>
                <li>Hindari analgerik seperti aspirin dan ibuprofen</li>
                <li>Atasi  stres dan rasa cemas</li>
                <li>Olahraga, relaksasi, cukup tidur dan BB ideal</li>
                <li>Kontrol ke klinik dan minum obat sesuai instruksi</li>
            </ul>
            `;
        }
        if (complaints.includes("Diare, sakit atau kram perut dan kembung")) {
            advice += `
            <h4>Sindrom Kolon Iritabel</h4>
            <ul>
                <li>Minum yoghurt untuk menyeimbangkan bakteri usus</li>
                <li>Makanan tinggi serat dan banyak minum air putih</li>
                <li>Olahraga seperti bersepeda untuk meningkatkan gerak usus</li>
                <li>Atasi stres dengan relaksasi dan  psikoterapi</li>
                <li>Bila perlu beri pencahar, atau suplemen serat</li>
                <li>Untuk mengatasi  stres dan cemas dapat diberi  anticemas atau antidepresan</li>
                <li>Kontrol ke Rumah Sakit teratur dan minum obat sesuai instruksi dokter</li>
            </ul>
            `;
        }

        if (complaints.includes("Khawatir berlebih menderita penyakit (misal jantung)")) {
            advice += `
            <h4>Penyakit Jantung Fungsional</h4>
            <ul>
                <li>Menyadari beda anatara kekhawatiran dan  keluhan sakit  jantung</li>
                <li>Cari penyebab kekhawatiran apakah ada kerabat  yang sakit dan meninggal</li>
                <li>Ajari mengenali emosinya dan coping mechanism yang sehat</li>
                <li>Untuk mengatasi  stres dan cemas dapat diberi  anticemas atau antidepresan</li>
                <li>Kontrol ke Rumah Sakit teratur dan minum obat sesuai instruksi dokter</li>
            </ul>
            `;
        }
        if (complaints.includes("Nyeri tubuh tertentu tanpa penyakit mendasari")) {
            advice += `
            <h4>Nyeri Psikogenik</h4>
            <ul>
                <li>Disarankan konseling atau psikoterapi dan Kognitif behavior terapi</li>
                <li>Hindari pemeriksaan medis berulang yang tidak diperlukan</li>
                <li>Eksplorasi faktor psikologis yang melatarbeloakangi;  depresi terselubung</li>
                <li>Bila ada gejala psikotik  obati dengan  antipsikotik</li>
            </ul>
            `;
        }
        if (complaints.includes("Kelelahan dan keletihan meski tidur cukup")) {
            advice += `
            <h4>Gangguan Fatigue</h4>
            <ul>
                <li>Olahraga seperti berjalan kaki, bersepeda, atau berenang</li>
                <li>Aktivitas fisik 3 X / minggu dengan durasi 30 menit</li>
                <li>Minum air putih 8 gelas / hari, hindari alkohol dan kafein</li>
                <li>Makan teratur, bergizi  dan tepat waktu</li>
                <li>Kelola stres, relaks, rekreasi, camping dsb.</li>
                <li>Jaga berat badan dan tidur  6 - 8 jam / hari dimalam hari</li>
                <li>Kontrol ke Rumah Sakit teratur dan minum obat sesuai instruksi dokter</li>
            </ul>
            `;
        }
        if (complaints.includes("Kulit gatal dan kemerahan (eksim)")) {
            advice += `
            <h4>Dermatitis a topic</h4>
            <ul>
                <li>Hindari penyebab iritasi</li>
                <li>Gunakan pelembap kulit agar  tidak kering</li>
                <li>Gunakan kompres untuk meredakan rasa gatal</li>
                <li>Hindari menggaruk area dermatitis kontak</li>
                <li>Lindungi tangan dengan membilas dan mengeringkan</li>
                <li>Kontrol ke Rumah Sakit teratur dan minum obat sesuai instruksi dokter</li>
            </ul>
            `;
        }
        if (complaints.includes("Sakit kepala tegang atau migraine")) {
            advice += `
            <h4>Psoriasis</h4>
            <ul>
                <li>Berjemur matahari yang lembut</li>
                <li>Jaga kebersihan kulit</li>
                <li>Hindari infeksi oleh bakteri</li>
                <li>Menghindari cedera kulit dan kulit kering</li>
                <li>Kontrol ke Rumah Sakit teratur dan minum obat sesuai instruksi dokter</li>
            </ul>
            `;
        }
        if (complaints.includes("Nyeri dada, punggung dan tulang belakang")) {
            advice += `
            <h4>Tennsion Headache</h4>
            <ul>
                <li>Istirahat di ruang gelap dan tenang</li>
                <li>Kelola stres dengan meditasi relaksasi atau yoga</li>
                <li>Hindari penyebab stres</li>
                <li>Kompres dengan air hangat atau dingin</li>
                <li>Meregangkan leher, punggung, dan bahu</li>
                <li>Makanan mengandung Kalium, kentang rebus</li>
                <li>Kontrol ke Rumah Sakit teratur dan minum obat sesuai instruksi dokter</li>
            </ul>
            `;
        }
        if (complaints.includes("Sesak nafas mengi (asma)")) {
            advice += `
            <h4>Low Back Pain</h4>
            <ul>
                <li>Kompres dingin pada punggung yang terasa nyeri selama 10 menit</li>
                <li>Obat pereda nyeri, untuk mengatasi rasa nyeri yang timbul</li>
                <li>Obat pelemas otot, seperti clonazepam dan chlorzoxazone, untuk mengurangi ketegangan otot pada bagian punggung bawah</li>
                <li>Kontrol ke Rumah Sakit teratur dan minum obat sesuai instruksi dokter</li>
            </ul>
            `;
        }
        if (complaints.includes("Kadar gula darah dan kadar kolesterol tinggi, hipertensi disertai lemak berlebihan di pinggang")) {
            advice += `
            <h4>Sindroma Metabolik</h4>
            <ul>
                <li>Banyak makan buah, sayur,bijian,susu rendah lemak, daging tanpa lemak</li>
                <li>Batasi makanan olahan, daging lemak jenuh</li>
                <li>Konsumsi daging merah, natrium dan gula tambahan</li>
                <li>Berolahraga setidaknya 150 menit / minggu</li>
                <li>Turunkan berat badan</li>
                <li>Kontrol ke Rumah Sakit teratur dan minum obat sesuai instruksi dokter</li>
            </ul>
            `;
        }
        advice += ``;
        return advice;
    }
    function getBMICategory(bmi) {
        if (!bmi) return "Tidak tersedia";
        if (bmi < 18.5) return "Kurus";
        if (bmi >= 18.5 && bmi < 24.9) return "Normal";
        if (bmi >= 25 && bmi < 29.9) return "Overweight";
        if (bmi >= 30) return "Obesitas";
        return "Tidak valid";
    }
</script>

<style scoped>
    select:invalid {
        color: grey;
    }
    .input-wrapper {
        position: relative;
        /* margin-bottom: 1.5rem; */
    }
    .input-wrapper input{
        width: 100%;
    }
    .error-border {
        border: 1px solid red;
    }
    .error-message {
        color: red;
        font-size: 0.85em;
        margin-top: 4px;
    }
    section{
        padding: 2.5rem 10% 2.5rem 10%;
        color: var(--grey);
    }
    .questionnaire {
        margin: auto;
        padding: 20px;
        border: 1px solid var(--softgrey);
        border-radius: 8px;
    }
    .progress-bar {
    width: 100%;
    height: 5px;
    background-color: #e0e0e0;
    position: relative;
    }
    .progress {
    height: 100%;
    background-color: #007bff;
    transition: width 0.3s ease;
    }
    .form-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px 20px;
    margin-top: 20px;
    }
    .form-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 10px;
    }
    .fr-border{
        border-bottom: 1px solid var(--softgrey);
    }
    .navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    }
    .submit-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    }
    .back-button,
    .next-button {
    padding: 8px 12px;
    background-color: #555;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    }
    .submit-button {
    padding: 10px 20px;
    background-color: var(--primary);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    }
    button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
    }
    .the-end span{
        padding: 1rem 2rem 1rem 2rem;
        color: var(--navy);
        font-family: 'Courier New', Courier, monospace;
        font-size: 2.5rem;
        font-weight: 700;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding: 2.5rem 5% 2.5rem 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding: 2.5rem 2.5% 2.5rem 2.5%;
        }
        .form-grid {
            grid-template-columns: 1fr;
        }
    }
</style>