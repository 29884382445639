import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { useDoctorStore } from './stores/doctor';
import './registerServiceWorker'

createApp(App).use(createPinia()).use(router).mount('#app')
// ==================================================== swipe menu for mobile view starts
let touchstartX = 0;
let touchendX = 0;
const nbmob = document.getElementById('navbar-menu');
const nbmobbg = document.getElementById('navbar-menu-bg');
function handleGesture() {
    if(touchendX > touchstartX){
        document.getElementById('navbar-menu').style.left = '110%';
        document.getElementById('navbar-menu-bg').style.width = '0';
    }
}
nbmob.addEventListener('touchstart', e => {
    touchstartX = e.changedTouches[0].screenX;
});
nbmob.addEventListener('touchend', e => {
    touchendX = e.changedTouches[0].screenX;
    handleGesture();
});
nbmobbg.addEventListener('touchstart', e => {
    touchstartX = e.changedTouches[0].screenX;
});
nbmobbg.addEventListener('touchend', e => {
    touchendX = e.changedTouches[0].screenX;
    handleGesture();
});
// ==================================================== swipe menu for mobile view ends
// ==================================================== when window is scrolled starts
window.onscroll = function(){
    const ltree = document.querySelector('.link-tree-icon');
    const gotop = document.getElementsByClassName('go-top'); // <================== scroll top icon
    var i;
    if(document.body.scrollTop > 100 || document.documentElement.scrollTop > 100){
        for(i = 0; i < gotop.length; i++){
            if(screen.width > 1500){
                gotop[i].style.bottom = '5%';
            }else if(screen.width > 750){
                gotop[i].style.bottom = '5%';
            }else{
                gotop[i].style.bottom = '5%';
            }
        }
        ltree.children[0].children[0].style.transform = 'rotate(-360deg)';
        ltree.style.right = '115px';
    }else{
        for(i = 0; i < gotop.length; i++){
            gotop[i].style.bottom = '-15%';
        }
        ltree.children[0].children[0].style.transform = 'rotate(0)';
        ltree.style.right = '50px';
    }
    const st = document.getElementsByClassName('sticky-top-board');
    if(st[0]){
        const stpsh = st[0].parentElement.offsetHeight - (st[0].offsetHeight - (66 - 16));
        if(document.body.scrollTop > 66 || document.documentElement.scrollTop > 66){
            if(document.body.scrollTop > stpsh || document.documentElement.scrollTop > stpsh){
                for(i = 0; i < st.length; i++){
                    st[i].classList.remove('stb-active');
                    st[i].classList.add('stb-absolute');
                }
            }else{
                for(i = 0; i < st.length; i++){
                    st[i].classList.remove('stb-absolute');
                    st[i].classList.add('stb-active');
                }
            }
        }else{
            for(i = 0; i < st.length; i++){
                st[i].classList.remove('stb-absolute');
                st[i].classList.remove('stb-active');
            }
        }
    }
    const ob = document.getElementById('sr-order-body');
    if(ob){
        if(screen.width < 1051){
            if(document.body.scrollTop > ((document.body.scrollHeight - screen.height) - 1) || document.documentElement.scrollTop > ((document.body.scrollHeight - screen.height) - 1)){
                if(ob.offsetHeight > 0){
                    ob.style.maxHeight = 0;
                    ob.previousElementSibling.children[0].innerHTML = 'Tampilkan';
                    ob.classList.add('hide-by-scroll');
                }
            }else{
                if(ob.classList.contains('hide-by-scroll')){
                    ob.style.maxHeight = '500px';
                    ob.previousElementSibling.children[0].innerHTML = 'Sembunyikan';
                    ob.classList.remove('hide-by-scroll');
                }
            }
        }
    }
}
// ==================================================== when window is scrolled ends
// ==================================================== when window is clicked starts
window.onclick = function(e){
    if(!e.target.classList.contains('float-button')){
        const float = document.getElementsByClassName('float');
        for(var i = 0; i < float.length; i++){
            float[i].classList.remove('float-active');
        }
        const arrow = document.getElementsByClassName('float-arrow');
        for(i = 0; i < arrow.length; i++){
            arrow[i].children[0].style.transform = 'rotate(0)';
        }
    }
    // if(e.target.classList.contains('sdp-modal')){
    //     const sm = document.getElementsByClassName('sdp-modal');
    //     for(i = 0; i < sm.length; i++){
    //         sm[i].style.display = 'none';
    //     }
    // }
    if(e.target.classList.contains('modal')){
        const modal = document.getElementsByClassName('modal');
        for(i = 0; i < modal.length; i++){
            modal[i].classList.remove('modal-active');
        }
        // console.log('Modal');
    }
    // if(e.target.classList.contains('navbar-login-form')){
    //     document.getElementById('navbar-login-form').style.display = 'none';
    // }
    // if(!e.target.classList.contains('trigger')){
    //     const float = document.getElementsByClassName('float');
    //     const ic = document.getElementById('srv-button-icon');
    //     if(ic){
    //         for(var i = 0; i < float.length; i++){
    //             float[i].style.display = 'none';
    //         }
    //         ic.children[0].style.transform = 'rotate(0)';
    //     }
    // }
    // if(e.target.classList.contains('sdp-modal')){
    //     const sm = document.getElementsByClassName('sdp-modal');
    //     for(i = 0; i < sm.length; i++){
    //         sm[i].style.display = 'none';
    //     }
    // }
}
// ==================================================== when window is clicked ends
// ==================================================== when window is resized starts
window.onresize = function(){
    if(document.getElementById('nlf-content')){
        document.getElementById('nlf-content').scrollLeft = 0; // login form
    }
}
// ==================================================== when window is resized ends
// ==================================================== when window is loaded starts
window.onload = function(){
    useDoctorStore().setSpecialists();
}
// ==================================================== when window is loaded ends